<template>
  <div class="crud-header-filter">
    <el-input v-if="type === 'string'" :model-value="modelValue" @update:modelValue="$emit('update:modelValue', $event)" size="small" placeholder="Type to search" />
    <el-input v-if="type === 'number'" :model-value="modelValue" @update:modelValue="$emit('update:modelValue', $event)" size="small" placeholder="Type to search" />
    <div v-if="type === 'show'">
      <v-select appendToBody :model-value="modelValue" @update:modelValue="$emit('update:modelValue', $event)" :options="availableShows" label="name" :reduce="p => p.id"/>
    </div>
    <div v-if="type === 'personality'">
      <v-select appendToBody :model-value="modelValue" @update:modelValue="$emit('update:modelValue', $event)" :options="availablePersonalities" label="full_name" :reduce="p => p.id"/>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue"
import { useRequest } from "@/mixins/useRequest";
import vSelect from "vue-select"

let { type, column, modelValue } = defineProps(["type", "column", "modelValue"])

let availableShows = ref([])
let availablePersonalities = ref([])

let getShows = useRequest("GET", "/api/shows/all")
let getPersonalities = useRequest("GET", "/api/personalities/all")

onBeforeMount(() => {
  if (type === "show") {
    getShows().then((res) => availableShows.value = res)
  } else if (type === "personality") {
    getPersonalities().then((res) => availablePersonalities.value = res)
  }
})
</script>

<style scoped>
.crud-header-filter >>> .vs__dropdown-toggle {
  height: 24px;
}
.crud-header-filter >>> .vs__selected {
  margin-top: 4px;
  line-height: 12px;
  align-items: start;
}
</style>
