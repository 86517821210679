<template>
  <div class="mt-5">
    <div>
      <crud-table endpoint="/api/personalities" :form="Form">
        <el-table-column prop="id" label="ID" width="60" sortable="custom" />
        <el-table-column prop="full_name" label="Full Name" width="180" sortable="custom" />
        <el-table-column prop="alternative_names" label="Alternative names" width="100">
          <template #default="scope">
            <div>{{ scope.row.alternative_names?.length || 0 }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="aliases" label="Aliases">
          <template #default="scope">
            <div>{{ scope.row.aliases?.join(", ")}}</div>
          </template>
        </el-table-column>
      </crud-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudTable from "@/components/crud/CrudTable.vue";
import PersonalityForm from "@/components/forms/PersonalityForm.vue";
import { formatDate } from "@/services/datetime";

@Options({
  methods: { formatDate },
  components: { CrudTable }
})
export default class AdminPersonalitiesView extends Vue {
  Form = PersonalityForm;
}
</script>
