<template>
  <div>
    <nav class="bg-gray-800">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="text-white font-bold text-xl">podcastr.me</span>
            </div>
            <div>
              <div class="ml-10 flex items-baseline space-x-4">
                <router-link class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" to="/">episodes</router-link>
                <router-link class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" to="/my-episodes">my episodes</router-link>
                <router-link class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" v-admin-only to="/custom-episodes">ultra secret</router-link>
                <router-link class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" v-admin-only to="/admin/episodes">admin</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <router-view/>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'

// state
const showAdmin = ref(false)

if (localStorage.getItem("podcastr-session-id")) {
  showAdmin.value = true;
}
</script>
<style lang="scss">
</style>
