<template>
  <div class="mt-5">
    <div>
      <div class="h-[30px]">
        <div v-if="selected">
          <router-link class="text-blue-600 hover:underline" :to="{ name: 'admin.episodes', query: { id: selected.id }}">
            {{ selected.name }}
          </router-link>
        </div>
      </div>
      <div ref="timelineContainer"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import * as vis from "vis-timeline/esnext";
import "vis-timeline/styles/vis-timeline-graph2d.css";
import { useRequest } from "@/mixins/useRequest"
import day from "dayjs"

let selected = ref(null);
let timelineContainer = ref(null);
let getTimeline = useRequest("GET", "/api/episodesx/timeline-all")
let getShows = useRequest("GET", "/api/shows/all")

onMounted(() => {
  Promise.all([
    getTimeline(),
    getShows()
  ]).then(([episodes, shows]) => {
    let formattedItems = []

    episodes.forEach((ep) => {
      if (!ep.datetime) return;
      // if (ep.show_id !== 2) return;

      formattedItems.push({
        id: ep.id,
        content: `${ep.episode_number}`,
        start: day(ep.datetime).toDate(),
        group: ep.show_id
      })
    })

    let groups = shows.map((show) => ({
      id: show.id,
      content: show.name
    }))

    let options = {
      groupOrder: 'content',
      stack: false,
      cluster: {
        maxItems: 2
      }
    };

    let timeline = new vis.Timeline(timelineContainer.value, formattedItems, groups, options);

    timeline.on('select', (ev) => {
      selected.value = episodes.find(e => e.id === ev.items[0])
    });
  })

})
</script>
<style>
.vis-timeline {
  font-size: 10px;
}
.vis-cluster .vis-item-content {
  background-color: #d0d0d0;
  color: #5d5d5d;
}
</style>
