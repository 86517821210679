<template>
  <el-select
    size="small"
    style="width: 240px"
    :model-value="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <el-option
      v-for="option in param.options"
      :key="option.name"
      :label="option.label"
      :value="option.name"
    />
  </el-select>
</template>
<script setup>
let { param, modelValue } = defineProps(["param", "modelValue"])
// let emit = defineEmits(["update:modelValue"])

console.log(param);
</script>
