import { IScrappedEpisode } from "../transformers/IScrappedEpisode";
import { includeIfIncludedBase } from "./guestsUtils";
import { sanitizeGuests } from "./guestsUtils/sanitizeGuest";

const HOME_TEAM = ["Patrick Bet-David", "Adam Sosnick", "Tom Ellsworth", "Vincent Oshana"]

pbdPlugin.regulars = HOME_TEAM;

export function pbdPlugin(ep: IScrappedEpisode): IScrappedEpisode {
  let guests = ep.guests || []
  let episode = ep.episode;
  let includeIfIncluded = includeIfIncludedBase(ep.description.substring(0, 300))

  guests = includeIfIncluded(guests, "Patrick Bet-David")
  guests = includeIfIncluded(guests, "Adam Sosnick")
  guests = includeIfIncluded(guests, "Tom Ellsworth")
  guests = includeIfIncluded(guests, "Vincent Oshana", ["Vinnie"])

  // disabled hometeam autofill
  if (ep.description.toLowerCase().substring(0, 400).includes("home team")) {
    guests = [...guests, ...HOME_TEAM]
  }

  if (ep.title.includes("EP") && !episode) {
    let foundEp = ep.title.match(/\| ?EP ?([0-9]{1,3})/)
    if (foundEp) {
      episode = +foundEp[1];
    }
  }

  ep.guests = sanitizeGuests(guests);
  ep.episode = ep.episode || episode

 return { ...ep, guests, episode }
}
