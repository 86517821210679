<template>
  <div>
    <router-link class="text-black" :to="{ name: 'admin.personalities', query: { id: personality.id }}">{{ personality.full_name }}</router-link> ({{ matches.length }})
    <div class="alternatives">alternatives: <span v-for="name in personality.alternative_names">{{ name }}</span></div>
    <div class="ms-2 container" v-for="match in matches" :key="match.id">
      <small class="text-secondary">
        <router-link :to="{ name: 'admin.personalities', query: { id: match.id }}">{{ match.full_name }}</router-link>

        <action-button class="ms-2 mb-1" type="danger" :promise="mergeInto.bind(undefined, match.id)">merge
          into</action-button>
      </small>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue"
import { useRequest } from "../mixins/useRequest"
import ActionButton from "../components/ActionButton"


let { personality, matches } = defineProps(["personality", "matches"])
let emit = defineEmits("merge")

let merge = useRequest("delete", (targetPersonalityId, deletePersonalityId) => `/api/personalities/${targetPersonalityId}/merge/${deletePersonalityId}`)

function mergeInto(personalityId) {
  emit("merge", { intoId: personality.id, id: personalityId})

  return merge(personalityId, personality.id)
}

</script>

<style scoped>
.alternatives {
  font-size: 10px;
  color: black;
  opacity: 0.4;
}
.container {
  line-height: 12px;
}
small {
  font-size: 10px;
  line-height: 12px;
}
</style>
