<template>
  <el-popconfirm v-if="confirm" title="Confirm merge?" @confirm="handleClick">
    <template #reference>
      <el-button :class="props.class" :loading="loading" type="primary" size="small">
        <slot />
      </el-button>
    </template>
  </el-popconfirm>
  <el-button v-if="!confirm" :class="props.class" :loading="loading" type="primary" size="small" @click="handleClick">
    <slot />
  </el-button>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue"
import { useRequest } from "@/mixins/useRequest";
import { ElNotification } from 'element-plus'

let props = defineProps({
  class: {
    default: "",
  },
  type: {
    default: "primary"
  },
  size: {
    default: "small"
  },
  promise: {
    required: false
  },
  confirm: {
    type: Boolean,
    default: false
  },
  getAction: {
    required: false,
    default: () => {
    }
  }
})
defineEmits(["click"])

let loading = ref(false);
let sendGetRequest = useRequest("GET", () => props.getAction)

function handleClick() {
  loading.value = true;
  
  let action = props.promise ? props.promise : sendGetRequest

  action()
      .then(res => {
        ElNotification({
          title: 'Success',
          message: JSON.stringify(res),
        })
      })
      .catch((res) => {
        ElNotification({
          title: 'Error',
          message: JSON.stringify(res),
        })
      })
      .finally(() => {
        loading.value = false;
      })
}
</script>

