<template>
  <div>
    <el-form label-width="200">
    <div v-for="param in params" :key="param.name" class="mb-2">
      <GuestParserCondition :condition="param.shownIf" :values="modelValue">
        <ElText size="small">{{ param.label }}</ElText>
        <div>
        <GuestParserTextParam v-if="param.type === 'string'" :param="param" v-model="modelValue[param.name]"/>
        <GuestParserNumberParam v-if="param.type === 'number'" :param="param" v-model="modelValue[param.name]" />
        <GuestParserSelectParam v-if="param.type === 'select'" :param="param" v-model="modelValue[param.name]" />
        </div>
      </GuestParserCondition>
    </div>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, watch } from 'vue'

import GuestParserNumberParam from "@/components/guestParser/GuestParserNumberParam";
import GuestParserSelectParam from "@/components/guestParser/GuestParserSelectParam";
import GuestParserTextParam from "@/components/guestParser/GuestParserTextParam";
import GuestParserCondition from "@/components/guestParser/GuestParserCondition";


let { params, modelValue } = defineProps(["params", "modelValue"])
</script>
