<template>
  <div>
    my episodes
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
  },
})
export default class MyEpisodesView extends Vue {}
</script>
