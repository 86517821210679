<template>
  <div class="task ms-1 ps-1 pt-3 pe-1" :class="containerClasses">
    {{ task.actionName }} <br>
    {{ finished }} / {{ total }}
  </div>
</template>

<script setup>
import  { uniq } from "lodash"
import { ref, computed } from "vue"
import { CircleCheckFilled } from "@element-plus/icons-vue"
import { state } from "@/services/socket"

let { task } = defineProps(["task"])

let total = computed(() => {
  return task.ids.length;
})

let finished = computed(() => {
  return uniq(task.updates.filter(update => update.finished).map(update => update.id)).length
})
let error = false

let containerClasses = {
  finished,
  error
}

</script>

<style scoped>
.task {
  line-height: 10px;
  font-size: 8px;
}

.task.finished {
  background-color: rgb(196, 217, 196);
}
.task.error {
  background-color: rgb(198, 86, 102);
}
</style>
