<template>
  <el-form-item :label="label">
    <div class="block">
      <div v-if="Array.isArray(items)">
        <div v-for="(item, index) in items" :key="index" class="flex mb-1">
          <el-input v-model="items[index]" @input="updateItem(index, $event.target.value)" />
          <el-button class="ms-1" @click="removeItem(index)">Remove</el-button>
        </div>
      </div>
      <el-button @click="addItem">Add Item</el-button>
    </div>
  </el-form-item>
</template>

<script setup lang="ts">

import { ref, onMounted, defineProps, defineEmits } from "vue";

const props = defineProps({
  label: {
    required: true,
    type: String
  },
  modelValue: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(["update:modelValue"]);

const items = ref([]);

onMounted(() => {
  console.log(Array.isArray(items.value), items.value);

  if (Array.isArray(props.modelValue)) {
    items.value = (props.modelValue || []).slice(0);
  }
  else {
    items.value = []
  }
});

const update = () => emit("update:modelValue", items.value);

const updateItem = (index, value) => {
  items.value[index] = value;
  update()
};

const removeItem = (index) => {
  items.value.splice(index, 1);
  update()
};

const addItem = () => {
  items.value.push("");
  update()
};
</script>
