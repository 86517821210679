import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_crud_table = _resolveComponent("crud-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_crud_table, {
        endpoint: "/api/personalities",
        form: _ctx.Form
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "id",
            label: "ID",
            width: "60",
            sortable: "custom"
          }),
          _createVNode(_component_el_table_column, {
            prop: "full_name",
            label: "Full Name",
            width: "180",
            sortable: "custom"
          }),
          _createVNode(_component_el_table_column, {
            prop: "alternative_names",
            label: "Alternative names",
            width: "100"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", null, _toDisplayString(scope.row.alternative_names?.length || 0), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "aliases",
            label: "Aliases"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", null, _toDisplayString(scope.row.aliases?.join(", ")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["form"])
    ])
  ]))
}