<template>
  <div class="scrape-json-view-container">
    <div class="flex mb-2">
      <div class="me-3">
        <div>G - Get guests from title + description</div>
        <div>N - Get names from title</div>
        <div>P - Execute plugin on the item</div>
        <div>S - Copy guests from selected item</div>
        <div>For selection, release shift to cancel multiline selection</div>
      </div>
      <div>
        <div>
          <el-button type="secondary" size="small" @click="reloadEditablePlugin">Reload editable plugin</el-button>
          <action-button :promise="guestsParser">Run guests parser</action-button>
          <action-button :promise="() => extendDry()">Dry run extend</action-button>
          <action-button :promise="() => extendDry(true)">Dry run extend with guest parser</action-button>
          <el-button size="small" type="primary" @click="fillEpNumber()">Fill EP from the bottom</el-button>
        </div>
        <div class="mt-1" v-if="selection.length > 0">
          <el-button size="small" type="primary" @click="deselect()">Deselect</el-button>
          <el-button size="small" type="primary" @click="runPluginOnSelection()">Run plugin</el-button>
          <el-dropdown class="ms-3 me-3">
            <el-button size="small" type="primary">Merge {{ selection.length }} episodes (pick main)</el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="item in selectionWithItems" @click="mergeSelected(item)">{{ item.title }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button size="small" type="primary" @click="deleteSelected()">Delete {{ selection.length }} episodes</el-button>
        </div>
      </div>
    </div>
    <div v-if="hint" class="mb-3 text-green-600">{{ hint }}</div>

    <SelectionContainer ref="selectionContainer" :selection="selection" @update:selection="onSelectionUpdate">
      <SelectionItem v-for="item in result.items" class="item"
                     :class="result.deleted.includes(item.guid) ? 'deleted' : ''"
                     :selection-by="item.guid"
                     :key="item.guid">
        <div class="flex">
          <div class="me-1 actions-col flex">
            <div @click="extendRow = extendRow ? '' : item.guid">v</div>
            <div @click="removeRow(item)">x</div>
          </div>
          <div class="me-1 episode-col">
            <span @click="onEditNumber(item.guid, $event)"><span v-if="item.episode">{{ item.episode }}</span><span
              class="number-muted" v-else>[N/A]</span></span>
            <input type="text" class="episode-number-input" v-model="item.episode" v-if="editRow === item.guid"
                   @keydown.enter="editRow = ''" />
          </div>
          <div class="me-1 plugin-col">
            <GuestTextButton text="P" @click="getItemFromPlugin(item)" />
          </div>
          <div class="me-1 description-col" @click="saveToClipboard(item.description)">
            <span class="description-box">D</span>
            <div class="description-tooltip">
              <HighlightNames :content="item.description" @onNameClick="onAdd(item, $event)" />
            </div>
          </div>
          <div class="me-1 title-col">
            <ExpandingCell @click="editRow = editRow ? '' : item.guid">
              <HighlightNames :content="item.title" @onNameClick="onAdd(item, $event)" />
            </ExpandingCell>
            <input type="text" class="episode-title-input" v-model="item.title" v-if="editRow === item.guid"
                   @keydown.enter="editRow = ''" />
          </div>
          <div class="me-1 duration-col">
            {{ item.duration }}
          </div>
          <div class="me-1 guests-col flex ps-2">
            <GuestTextButton text="G" @click="getGuests(item)" />
            <GuestTextButton text="N" @click="getNames(item)" />
            <GuestTextButton text="P" @click="getItemFromPlugin(item)" />
            <GuestTextButton v-if="selection.length === 1" text="S" @click="getGuestsFromSelection(item)" />

            <GuestList :available-names="availableNames" :guests="item.guests" @remove="onRemove(item, $event)"
                       @add="onAdd(item, $event)" :regulars="regulars" />
          </div>
        </div>
        <div v-if="extendRow === item.guid">
          <div v-for="(value, key) in item">
            <div v-if="key === 'link'">{{key}}: <a :href="value" target="_blank" class="text-blue-600">open in new tab</a></div>
            <div v-else>{{ key }}:{{ value }}</div>
          </div>
        </div>
      </SelectionItem>
    </SelectionContainer>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";

import ExpandingCell from "@/components/scrapeJsonView/ExpandingCell.vue"
import GuestList from "@/components/scrapeJsonView/GuestList.vue"
import GuestTextButton from "@/components/scrapeJsonView/GuestTextButton.vue"
import { useRequest } from "@/mixins/useRequest"
import { uniq } from "lodash"
import HighlightNames from "@/components/scrapeJsonView/HighlightNames.vue"
import SelectionContainer from "@/components/scrapeJsonView/SelectionContainer.vue"
import SelectionItem from "@/components/scrapeJsonView/SelectionItem.vue"
import ActionButton from "@/components/ActionButton.vue"
import transformerPlugins from "scraper/transformerPlugins";

let selectionContainer = ref(null)
let selection = ref([])
let editRow = ref("");
let extendRow = ref("");
let regulars = ref([]);
let { plugin, result, scrapeId } = defineProps(["plugin", "result", "scrapeId"]);

let availableNames = ref([])
let getRegulars = useRequest("GET", "/api/transformer-plugin/" + plugin)
let getAiNames = useRequest("POST", "/api/ai-prompt/getNames")
let getAiGuests = useRequest("POST", "/api/ai-prompt/getGuests")
let execPlugin = useRequest("POST", "/api/transformer-plugin/" + plugin)
let getGuestsParserJson = useRequest("GET", `/api/scrapes/${scrapeId}/guests-parser-dry`)
let getExtendJson = useRequest("GET", `/api/scrapes/${scrapeId}/extend-dry`)
let getExtendJsonWithGuestParser = useRequest("GET", `/api/scrapes/${scrapeId}/extend-dry?useGuestParser=true`)

let hint = transformerPlugins[plugin].hint

let selectionWithItems = computed(() => selection.value.map(guid => result.items.find(item => item.guid === guid)))

onMounted(() => {
  result.items.forEach(item => {
    availableNames.value.push(...(item.guests || []))
  })
  availableNames.value = uniq(availableNames.value);

  return getRegulars().then(res => regulars.value = res.regulars || [])
})

function reloadEditablePlugin() {

}
function onSelectionUpdate(newVal) {
  selection.value = [...newVal]
}
function deleteSelected() {
  selection.value.forEach((guid) => removeRow(result.items.find(itm => itm.guid === guid)))
}
function mergeSelected(mainItem) {
  // remove all except the main item
  selection.value
    .filter(guid => guid !== mainItem.guid)
    .forEach((guid) => removeRow(result.items.find(itm => itm.guid === guid)))

  // sum duration of all items, update main item with said duration
  let sum = selection.value
    .map((guid) => result.items.find(itm => itm.guid === guid))
    .map(item => item.duration)
    .filter(Boolean)
    .reduce((acc, cur) => acc + cur, 0);

  mainItem.duration = sum;
}
function deselect() {
  selectionContainer.value.clearSelection()
}
function guestsParser() {
  return getGuestsParserJson().then(res => {
    result.items.length = 0;
    res.items.forEach(i => {
      result.items.push(i)
    })
  })
}
function extendDry(useGuestParser = false) {
  return (useGuestParser ? getExtendJsonWithGuestParser() : getExtendJson()).then((res) => {
    result.items.length = 0;
    res.items.forEach(i => {
      result.items.push(i)
    })
  })
}
function saveToClipboard(str) {
  navigator.clipboard.writeText(str)
}
function removeRow(item) {
  let foundItem = result.items.findIndex(itm => itm === item);
  if (foundItem !== -1) {
    let guid = result.items[foundItem].guid

    if (result.deleted.includes(guid)) {
      result.deleted.splice(result.deleted.findIndex((item => item === guid)), 1)
    }
    else {
      result.deleted.push(guid)
    }
  }
}
function onEditNumber(guid, $event) {
  editRow.value = editRow.value ? '' : guid
  setTimeout(() => $event.target.parentNode.parentNode.querySelector("input").focus(), 100)
}
function onRemove(item, guest) {
  item.guests = item.guests.filter(g => g !== guest)
}
function onAdd(item, guest) {
  if (item.guests.includes(guest)) return;
  item.guests.push(guest)
}
function getNames(item) {
  getAiNames({
    text: item.title
  }).then((res) => {
    item.guests = uniq((item.guests || []).concat(res.result))
  })
}
function getGuests(item) {
  getAiGuests({
    text: item.description
  }).then(res => {
    item.guests = uniq((item.guests || []).concat(res.result));
  })
}
function getGuestsFromSelection(item) {
  item.guests = selectionWithItems.value[0].guests.slice(0);
}
function runPluginOnSelection() {
  selectionWithItems.value.forEach(item => {
    getItemFromPlugin(item)
  })
}
function getItemFromPlugin(item) {
  let pluginRes = transformerPlugins[plugin](item)
}
function fillEpNumber() {
  let highestEp = 0;
  let highestAtIndex = 0;
  let skipped = 0;
  result.items.toReversed().forEach((item, index) => {
    if (result.deleted.includes(item.guid)) {
      skipped++;
      return;
    }

    if (item.episode && (+item.episode > 10)) {
      highestEp = +item.episode
      highestAtIndex = index;
      skipped = 0;
    }
    else {
      console.error("missing lowest episode (e.g. first ep needs to have episode number)")
      item.episode = highestEp + (index - highestAtIndex) - skipped;
    }
  })
}
</script>

<style scoped>
.deleted {
  opacity: 0.3;
  text-decoration: line-through;
}
.item {
  margin-bottom: 1px;
}
.item:hover {
  margin-bottom: 0;
  border-bottom: 1px solid #dadada;
}
.episode-col {
  width: 30px;
}

.title-col {
  width: 300px;
}

.guests-col {
  width: 600px;
}
.actions-col {
  width: 26px;
}
.actions-col > div {
  cursor: pointer;
  text-align: center;
  padding: 0 2px;
}
.actions-col > div:hover {
  cursor: pointer;
  background: #e8e8e8;
}

.description-col {
  width: 20px;
  position: relative;
}
.plugin-col {
  width: 16px;
  position: relative;
}
.description-tooltip {
  display: none;
  z-index: 100;
  padding: 8px;
  width: 600px;
  background: #e8e8e8;
  border: 1px solid #ffc9c9;
  position: absolute;
  left: 5px;
}
.description-col:hover .description-tooltip {
  display: block;
}
.duration-col {
  width: 27px;
}

.description-box {
  cursor: pointer;
  width: 20px;
}

.scrape-json-view-container {
  font-size: 10px;
}
.episode-number-input {
  width: 40px;
}
.episode-title-input {
  width: 300px;
}
.episode-number-input, .episode-title-input {
  font-size: 10px;
  height: 12px;
}
.number-muted {
  opacity: 0.3;
}
</style>
