import { IScrappedEpisode } from "../transformers/IScrappedEpisode";

diaryOfACeoPlugin.regulars = [];
diaryOfACeoPlugin.customPrompt = ""

export function diaryOfACeoPlugin(ep: IScrappedEpisode): IScrappedEpisode {
  let toRemove = false;
  let episode;

  if (ep.title.startsWith("Moment ")) {
    toRemove = true
  }

  let matches = ep.title.match(/^E[0-9]{1,3}/)
  if (matches) {
    episode = matches[0].replace("E", "")
  }
  else {
    episode = ep.episode
  }
  return { ...ep, episode, toRemove }
}
