import { pbdPlugin } from './pbdPlugin';
import { joeRoganPlugin } from './joeRoganPlugin';
import { mikhalayaPetersonPodcastPlugin } from './mikhalayaPetersonPodcastPlugin';
import { timcastIrlPlugin } from './timcastIrlPlugin';
import { diaryOfACeoPlugin } from './diaryOfACeoPlugin';
import { modernWisdomPlugin } from './modernWisdomPlugin';
import { hubermanLabPlugin } from './hubermanLabPlugin';
import { lexFridmanPlugin } from './lexFridmanPlugin';
import { jordanPetersonPlugin } from './jordanPetersonPlugin';

export default { pbdPlugin, joeRoganPlugin, mikhalayaPetersonPodcastPlugin,
  timcastIrlPlugin, diaryOfACeoPlugin, modernWisdomPlugin, hubermanLabPlugin,
  lexFridmanPlugin, jordanPetersonPlugin
}

