import { IScrappedEpisode } from "../transformers/IScrappedEpisode";
import { sanitizeGuests } from "./guestsUtils/sanitizeGuest";

let regulars = ["Jordan B. Peterson"]

jordanPetersonPlugin.regulars = regulars;

export function jordanPetersonPlugin(ep: IScrappedEpisode): IScrappedEpisode {
  let regex = /(\d+)\.\s+(.*?)\s+\|\s+(.+)/;
  let episode = 0;
  let guests: string[] = [];
  let title = ep.title;

  const matches = ep.title.match(regex);
  if (matches && matches?.length == 4) {
      episode = parseInt(matches[1], 10)
      title = matches[2].trim()
      guests = [matches[3].trim()]
  }

  if (title && matches?.length === 4) {
    ep.originalTitle = ep.title
    ep.title = title;
    console.log("title updated", title)
  }

  ep.episode = ep.episode || episode
  ep.guests = sanitizeGuests(guests)

  return ep
}
