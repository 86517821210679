import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import ElementPlus from "element-plus"
import JsonViewer from "vue3-json-viewer";
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import posthog from "./services/posthog"
import "vue3-json-viewer/dist/index.css";
import "./services/socket"

import "./style.css"
import "element-plus/dist/index.css"
import "vue-select/dist/vue-select.css";
import { adminOnlyDirective } from "./directives/adminOnly";

if (window.location.search && window.location.search.includes("setToken")) {
  let theToken = window.location.search.replace("?", "").replace("setToken=", "");
  localStorage.setItem("podcastr-session-id", theToken);
  window.location.search = ""
}

let app = createApp(App)

app
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(JsonViewer)
  .use(VueMonacoEditorPlugin, {
    paths: {
      // You can change the CDN config to load other versions
      vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs"
    },
  })


app.directive("admin-only", adminOnlyDirective)


if (!localStorage.getItem("podcastr-disable-tracking") && process.env.VUE_APP_POSTHOG_TOKEN) {
  app.use(posthog)
}

app.mount("#app");
