<template>
  <el-form label-width="120px">
    <el-form-item label="Import name">
      <el-input v-model="form.file_name" />
    </el-form-item>
    <el-form-item label="Show">
      <el-select v-model="form.show_id" filterable placeholder="Show">
        <el-option
          v-for="show in shows"
          :key="show.id"
          :label="show.name"
          :value="show.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="CSV File">
      <el-input v-model="form.csv" type="textarea" />
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import { Options, Vue, mixins } from "vue-class-component";
import { cloneDeep } from "lodash"
import Field from "@/components/field/Field.vue";
import CrudFormMixin from "@/components/crud/CrudFormMixin";
import RequestMixin from "@/mixins/RequestMixin";
import GuestParserOptions from "@/components/guestParser/GuestParserOptions.vue";
import vSelect from "vue-select";

@Options({
  components: { GuestParserOptions, Field, vSelect },
  props: { modelValue: Object },
  mixins: [CrudFormMixin]
})
export default class EpisodeForm extends mixins(RequestMixin) {
  modelValue!: any;
  form: any = {
    file_name: "",
    show_id: null,
    csv: ""
  };

  shows = [];

  created() {
    if (this.modelValue && this.modelValue.id) {
      this.form = cloneDeep(this.modelValue)
    }

    this.request("GET", "/api/shows/all").then(res => {
      this.shows = res;
    })
  }
}
</script>
