<template>
  <div class="mt-5">
    <div>
      <crud-table endpoint="/api/episodes" :form="Form" inline-bulk>
        <el-table-column prop="id" label="ID" width="60" />
        <el-table-column prop="episode_number" label="No" width="60" />
        <el-table-column prop="name" label="Name" width="180">
          <template #default="scope">
            <router-link class="hover:underline text-blue-600" :to="{ name: 'episode-detail', params: { id: scope.row.id || 0 } }">{{scope.row.name}}</router-link>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="Description" width="180">
          <template #default="scope">
            <div class="truncate" :title="scope.row.description">{{scope.row.description}}</div>
            <div class="extra-small">{{scope.row.guid}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="datetime" label="Air Date" width="120">
          <template #default="scope">
            {{formatDate(scope.row.datetime)}}
          </template>
        </el-table-column>
        <el-table-column prop="show_id" label="Show" width="180">
          <template #default="scope">
            {{scope.row.show_name}}
          </template>
        </el-table-column>
        <el-table-column prop="personality_id" label="Personalities" width="180" filterable="personality" :sortable="false">
          <template #default="scope">
            <div v-for="personality in scope.row.personalities"> <el-text size="small">{{ personality.full_name}} <el-tag v-if="personality.appeared_as === 'host'">Host</el-tag></el-text></div>
          </template>
        </el-table-column>
        <el-table-column label="Features" width="200" :sortable="false">
          <template #default="scope">
            <div>
              <el-tag v-if="scope.row.hasTranscriptRequest" type="warning" class="mb-1 me-1">remote-transcript-requested</el-tag>
              <el-tooltip v-for="feature in scope.row.features" :key="feature.id" :content="feature.value">
                <el-tag class="mb-1 me-1" @click="saveToClipboard(feature.value)">{{ feature.type }}</el-tag>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </crud-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudTable from "@/components/crud/CrudTable.vue";
import EpisodeForm from "@/components/forms/EpisodeForm.vue";
import { formatDate } from "@/services/datetime";

@Options({
  methods: { formatDate },
  components: {
    CrudTable
  }
})
export default class AdminEpisodesView extends Vue {
  Form = EpisodeForm;

  saveToClipboard(value) {
    navigator.clipboard.writeText(value)
  }
}
</script>
<style scoped>
.extra-small {
  font-size: 8px;
}
</style>
