export function durationToSeconds(timeString: any) {
  if (!timeString) {
    return 0
  }

  if (typeof timeString === "number") {
    return timeString
  }

  if (timeString.includes(":")) {
    let parts = timeString.split(":");

    let hours = parseInt(parts[0]);
    let minutes = parseInt(parts[1]);
    let seconds = parseInt(parts[2]);

    return (hours * 3600) + (minutes * 60) + seconds;
  }
  else if ((timeString as any) == +timeString) { // shut up TS, I'm trying to be smart
    return +timeString;
  }
  else {
    return 0;
  }
}
