<template>
  <el-form label-width="160px">
    <el-form-item label="Show name">
      <el-select v-model="form.show_id" filterable placeholder="Show">
        <el-option
          v-for="show in shows"
          :key="show.id"
          :label="show.name"
          :value="show.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Initial">
      <el-checkbox v-model="form.initial" label="Initial" size="small" />
    </el-form-item>
    <el-form-item label="Transformer">
      <v-select class="w-100" v-model="form.transformer" :options="availableTransformers" label="Transformer"/>
    </el-form-item>
    <el-form-item label="Transformer plugin">
      <v-select class="w-100" v-model="form.transformer_plugin" :options="availableTransformerPlugins" label="Transformer plugin"/>
    </el-form-item>
    <el-form-item label="Guests Parser">
      <v-select class="w-100" v-model="form.guest_parser" :options="availableGuestParsers" label="name" :reduce="(row) => row.name"/>
    </el-form-item>
    <el-form-item label="Guests Parser Params">
      <el-input type="textarea" v-model="form.guest_parser_params" />
    </el-form-item>
    <Field label="Source" v-model="form.source" />
  </el-form>
</template>

<script lang="ts">
import { Options, Vue, mixins } from "vue-class-component";
import { cloneDeep } from "lodash"
import Field from "@/components/field/Field.vue";
import CrudFormMixin from "@/components/crud/CrudFormMixin";
import RequestMixin from "@/mixins/RequestMixin";
import GuestParserOptions from '@/components/guestParser/GuestParserOptions.vue';
import vSelect from "vue-select";

@Options({
  components: { GuestParserOptions, Field, vSelect },
  props: { modelValue: Object },
  mixins: [CrudFormMixin]
})
export default class EpisodeForm extends mixins(RequestMixin) {
  modelValue!: any;
  form:any = {
    show_id: null,
    source: "",
    initial: true
  };

  shows = [];
  availableTransformers = []
  availableTransformerPlugins = []
  availableGuestParsers = []

  created() {
    if (this.modelValue && this.modelValue.id) {
      this.form = cloneDeep(this.modelValue)
    }

    this.request("GET", "/api/shows/all").then(res => {
      this.shows = res;
    })

    this.request("GET", "/api/transformers").then(res => {
      this.availableTransformers = res;
    })

    this.request("GET", "/api/transformer-plugins").then(res => {
      this.availableTransformerPlugins = res;
    })

    this.request("GET", "/api/guest-parsers").then(res => {
      this.availableGuestParsers = res;
    })
  }
}
</script>
