import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_Field = _resolveComponent("Field")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-width": "160px" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Show name" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.form.show_id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.show_id) = $event)),
            filterable: "",
            placeholder: "Show"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shows, (show) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: show.id,
                  label: show.name,
                  value: show.id
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Initial" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox, {
            modelValue: _ctx.form.initial,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.initial) = $event)),
            label: "Initial",
            size: "small"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Transformer" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            class: "w-100",
            modelValue: _ctx.form.transformer,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.transformer) = $event)),
            options: _ctx.availableTransformers,
            label: "Transformer"
          }, null, 8, ["modelValue", "options"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Transformer plugin" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            class: "w-100",
            modelValue: _ctx.form.transformer_plugin,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.transformer_plugin) = $event)),
            options: _ctx.availableTransformerPlugins,
            label: "Transformer plugin"
          }, null, 8, ["modelValue", "options"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Guests Parser" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_select, {
            class: "w-100",
            modelValue: _ctx.form.guest_parser,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.guest_parser) = $event)),
            options: _ctx.availableGuestParsers,
            label: "name",
            reduce: (row) => row.name
          }, null, 8, ["modelValue", "options", "reduce"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Guests Parser Params" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            type: "textarea",
            modelValue: _ctx.form.guest_parser_params,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.guest_parser_params) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_Field, {
        label: "Source",
        modelValue: _ctx.form.source,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.source) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}