import { IScrappedEpisode } from "../transformers/IScrappedEpisode";
import { sanitizeGuests } from "./guestsUtils/sanitizeGuest";

let regulars = ["Chris Williamson"]

modernWisdomPlugin.regulars = regulars;

export function modernWisdomPlugin(ep: IScrappedEpisode): IScrappedEpisode {
  let regex = /(?<=- )[^-?]+(?= -)/g;
  let match = ep.title.match(regex);
  let guest = match ? match[0].trim() : null;
  let guests = guest ? [guest] : []

  ep.episode = ep.episode || 0;
  ep.guests = sanitizeGuests(guests);

  return ep
}
