import { request } from "@/services/request";
import { ElMessage } from "element-plus";

export type IUrlBuilder = (...params: string[]) => string;

export function useRequest(method: string, url: string | IUrlBuilder) {
  return (data1?, data2?) => {
    let finalUrl = typeof url === "function" ? url(data1, data2) : url;

    return request(method, finalUrl, data2 || data1).catch((e) => {
      ElMessage.error(`Request failed: ${e.response.status} ${e.response.statusText}`)

      return Promise.reject(e)
    });
  }
}
