<template>
  <div class="container mx-auto px-4 py-2">
      <div class="flex gap-2 mb-2">
        <div class="w-1/2">
          Personality: <v-select class="w-full bg-white text-gray-800 border-gray-300 rounded-md shadow-sm" v-model="selectedPersonality" :options="availablePersonalities" label="full_name" :reduce="p => p.id" />
        </div>
        <div class="w-1/2">
          Show: <v-select class="w-full bg-white text-gray-800 border-gray-300 rounded-md shadow-sm" v-model="selectedShow" :options="availableShows" label="name" :reduce="p => p.id" />
        </div>
        <div v-if="selectedPersonality" class="col-12">only guests appearances are displayed</div>
      </div>

      <Episode v-for="episode in episodes" :episode="episode" :key="episode.id" @click:personality="onPersonalityClick" @click:show="onShowClick" />
      <div class="mb-4"><button @click="handleLoadMore()">Load More</button></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { request } from "@/services/request";
import Episode from "@/components/Episode.vue";
import vSelect from "vue-select"

@Options({
  components: {
    Episode,
    vSelect
  },
  data() {
    return {
      selectedPersonality: null,
      selectedShow: null,
      availableShows: [],
      availablePersonalities: [],
      episodes: [],
      page: 1,
    }
  },
  created() {
    this.loadEpisodes()
    request("GET", "/api/public/personalities").then(res => {
      this.availablePersonalities = res;
    })
    request("GET", "/api/public/shows").then(res => {
      this.availableShows = res;
    })
  },
  methods: {
    handleLoadMore() {
      this.page++;
      this.loadEpisodes();
    },
    loadEpisodes() {
      let query = new URLSearchParams()

      if (this.selectedShow) {
        query.append("show", this.selectedShow)
      }
      if (this.selectedPersonality) {
        query.append("personality", this.selectedPersonality)
      }
      if (this.page) {
        query.append("page", this.page)
      }

      request("GET", `/api/public/episodes?${query.toString()}`).then(res => {
        if (this.page > 1) {
          this.episodes.push(...res);
        }
        else {
          this.episodes = res;
        }
      })
    },
    onFilter() {
      this.page = 1;
      this.loadEpisodes();
    },
    onPersonalityClick(id) {
      this.page = 1;
      this.selectedPersonality = id;
    },
    onShowClick(id) {
      this.page = 1;
      this.selectedShow = id;
    }
  },
  watch: {
    selectedPersonality() {
      this.onFilter()
    },
    selectedShow() {
      this.onFilter()
    }
  }

})
export default class EpisodesView extends Vue {
}
</script>
