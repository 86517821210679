export const adminOnlyDirective = {
  mounted(el, binding) {
    const sessionId = localStorage.getItem("podcastr-session-id");
    if (!sessionId) {
      el.style.display = 'none';
    }
  },
  updated(el, binding) {
    const sessionId = localStorage.getItem("podcastr-session-id");
    el.style.display = sessionId ? '' : 'none';
  }
};
