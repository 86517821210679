<template>
  <div class="container mx-auto px-4 py-2">
    <div class="mb-2" v-admin-only>
      <router-link class="hover:underline text-blue-600" :to="{ name: 'admin.episodes', query: { id: episode.id }}">show in admin</router-link>
    </div>
    <div class="text-sm">
      <episode-prop :episode="episode" prop="id" />
      <episode-prop :episode="episode" prop="guid" />
      <episode-prop :episode="episode" prop="show_id" />
      <episode-prop :episode="episode" prop="episode_number" />
      <episode-prop :episode="episode" prop="name" />
      <episode-prop :episode="episode" prop="original_title" />
      <episode-prop :episode="episode" prop="description" />
      <episode-prop :episode="episode" prop="duration" />
      <episode-prop :episode="episode" prop="links" />
      <episode-prop :episode="episode" prop="created" />

      <div class="mt-4"><episode-personality v-for="p in episode.personalities" :personality="p" /></div>
      
      <div class="mt-4"><episode-feature v-for="feature in episode.features" :feature="feature" /></div>
    </div>
  </div>
</template>

<script setup>
import { useRequest } from "@/mixins/useRequest";
import { useRoute } from "vue-router";
import { ref } from "vue";
import EpisodeFeature from "@/components/frontend/EpisodeFeature.vue";
import EpisodePersonality from "@/components/frontend/EpisodePersonality.vue";
import EpisodeProp from "@/components/frontend/EpisodeProp.vue";


let episode = ref(null);
let route = useRoute();
let getEpisode = useRequest("GET", `/api/public/episodes/${route.params.id}`);

getEpisode().then((ep) => {
  episode.value = ep;
})
</script>
