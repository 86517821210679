<template>
  <div @click.shift.prevent.stop.capture="handleShiftClick(item)" :class="isSelected ? 'selected' : ''">
    <slot />
  </div>
</template>

<script setup>
import { ref, inject, computed, onMounted } from "vue"
let { selectionBy } = defineProps(["selectionBy"])

let selection = inject("selection");
let itemsOrder = inject("selectionItemsOrder");
let last = inject("selectionLast");

let isSelected = computed(() => {
  return selection.includes(selectionBy)
})

onMounted(() => {
  itemsOrder.push(selectionBy)
})

function handleShiftClick() {
  // event with preventDefault, selections are still created
  document.getSelection().removeAllRanges();

  let foundIndex = selection.indexOf(selectionBy)
  if (foundIndex !== -1) {
    selection.splice(foundIndex, 1)
  }
  else {
    last.value = selectionBy
    selection.push(selectionBy)
  }
}

</script>
<style scoped>
.selected {
  background: #ccccff;
}
</style>

