<template>
  <div>
    <div v-for="personality in personalities">{{ personality.full_name }}</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { request } from "@/services/request";

@Options({
  components: {
  },
  data() {
    return {
      personalities: []
    }
  },
  created() {
    request("GET", "/api/personalities").then(res => {
      this.personalities = res;
    })
  }
})
export default class PersonalitiesView extends Vue {}
</script>
