<template>
  <div class="flex guest-list-container" @mouseover="hover = true" @mouseleave="hover = false">
    <ElTag class="me-1" v-for="guest in guests" :key="guest" closable @close="$emit('remove', guest)">{{ guest }}</ElTag>
    <el-button v-if="hover" plain size="small" class="me-1" v-for="regular in absentRegulars" :key="regular" @click="$emit('add', regular)">+ {{ regular }}</el-button>
    <el-autocomplete
      v-if="hover"
      v-model="manualEntry"
      :fetch-suggestions="getSuggestions"
      class="me-1 manual-entry-input"
      size="small"
      @keypress.enter="onManualEntry"
      select="onManualEntry"
    />
    <el-button v-if="hover" plain size="small" @click="onManualEntry">+</el-button>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { throttle } from "lodash"
let props = defineProps(["guests", "regulars", "availableNames"]);
let emits = defineEmits(["add", "remove"])
let manualEntry = ref("");
let hover = ref(false)

let absentRegulars = computed(() => {
  return props.regulars.filter(r => !props.guests.includes(r))
})

function getSuggestions(query, cb) {
  query = query.toLowerCase();
  if (!query) return [];

  cb(props.availableNames.filter((name => name.toLowerCase().includes(query))).map(f => ({ value: f})))
}

const onManualEntry = throttle(() => {
  emits("add", manualEntry.value);
  manualEntry.value = "";
}, 100)
</script>

<style scoped>
.el-tag {
  height: 12px;
  font-size: 10px;
}
.el-tag >>> .el-tag__close {
  font-size: 8px;
  margin-top: -2px;
}
.guest-list-container {
  flex-grow: 1;
}
.guest-list-container .el-button {
  display: flex;
}
.el-button {
  height: 12px;
  font-size: 10px;
}

.guest-list-container /deep/ .manual-entry-input {
  display: flex;
  width: 100px;
  height: 12px;
  font-size: 10px;
}

</style>
