import { IScrappedEpisode } from "../transformers/IScrappedEpisode";
import { sanitizeGuests } from "./guestsUtils/sanitizeGuest";

let regulars = ["Lex Fridman"]

lexFridmanPlugin.regulars = regulars;

export function lexFridmanPlugin(ep: IScrappedEpisode): IScrappedEpisode {
  let regex = /^\#([0-9]{1,3}) ?[–-]{1} ?(.*): (.*)$/i
  let belowEp72Regex = /^(.*): (.*)$/i
  let splitRegex = /,|vs |& | and /
  let matches = ep.title.match(regex);

  let episode = 0;
  let guests: string[] = [];

  if (matches) {
    episode = +matches[1];
    guests = matches[2].split(splitRegex);  // Splitting guests into groups if there are multiple guests
    // title = matches[3];
  }
  if (!matches) {
    let matches = ep.title.match(belowEp72Regex);
    episode = 0;
    guests = matches ? matches[1].split(splitRegex) : []
  }

  ep.episode = ep.episode || episode
  console.log(guests);
  ep.guests = sanitizeGuests(guests)

  return ep
}
