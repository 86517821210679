import { uniq } from "lodash"

export const sanitizeGuest = (guest: string) => {
  return guest.trim();
}

export const sanitizeGuests = (guests: string[]) => {
  if (!Array.isArray(guests)) return guests;

  return uniq(guests.map(sanitizeGuest))
}
