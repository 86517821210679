import { sanitizeGuests } from "scraper/transformerPlugins/guestsUtils/sanitizeGuest";
import { includeIfIncludedBase } from "scraper/transformerPlugins/guestsUtils";

export const codeStart = `(function b(item, { sanitizeGuests, includeIfIncludedBase }) { `

export function runEditablePlugin(item, code) {
  let evalStr = `${codeStart}${code} })`;
  let pluginFn = eval(evalStr)
  let newEp = pluginFn(item, { sanitizeGuests, includeIfIncludedBase })
  if (!newEp) return;

  return newEp;
}
