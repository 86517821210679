<template>
  <div class="mt-5">
    <div>
      <crud-table endpoint="/api/babysitter-shows" :form="ShowForm" edit-only inline-bulk>
        <el-table-column prop="id" label="Show ID" width="100" sortable="custom" />
        <el-table-column prop="name" label="Show Name" width="180" sortable="custom" />
        <el-table-column prop="scrapes" label="New Episodes">
          <template #default="scope">
            <div v-if="Array.isArray(scope.row.scrapes) && scope.row.scrapes[0]?.result">
              <strong>{{ countItems(scope.row.scrapes[0].result) }}</strong> ({{ formatDate(scope.row.scrapes[0].created) }})
              <router-link :to="{ name: 'admin.scrapes', query: { id: scope.row.scrapes[0].id }}">{{ scope.row.scrapes[0].id }}</router-link>
            </div>
            <div v-else>N/A</div>
          </template>
        </el-table-column>
      </crud-table>
    </div>
  </div>
</template>

<script setup>
import CrudTable from "@/components/crud/CrudTable.vue";
import ShowForm from "@/components/forms/ShowForm.vue";
import day from "dayjs"

// result is not pgsql jsonb, must parse on each row. May refactor if causing problems
function countItems(result) {
  if (!result) return 0;

  let parsed = JSON.parse(result);

  if (!parsed || !parsed.items || parsed.items.length === 0) {
    return;
  }

  return parsed.items.filter(i => !(parsed.deleted || []).includes(i.guid)).length
}

function formatDate(date) {
  return day(date).format("YYYY-MM-DD HH:mm:ss")
}


</script>
