<template>
  <div class="flex">
    <div>
      <pre>{{codeStart}} ...</pre>
      <VueMonacoEditor v-model:value="code" theme="vs-dark" width="800px" height="600px" language="javascript" :options="editorOptions"/>
    </div>
    <div class="ms-2">
      <el-button @click="setCode">run plugin</el-button>
      <PluginResult v-for="item in result.items" :key="item.guid || item.title" :item="item" :code="runtimeCode" />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"
import { VueMonacoEditor } from "@guolao/vue-monaco-editor"
import PluginResult from "@/components/pluginEditor/PluginResult.vue"

import { codeStart } from "webapp/src/services/runEditablePlugin"

let { result, scrapeId, showId } = defineProps(["result", "scrapeId", "showId"])
let code = ref("")
let runtimeCode = ref("")

let editorOptions = {
  formatOnPaste: true,
  formatOnType: true
}

function setCode() {
  runtimeCode.value = code;
}

</script>
