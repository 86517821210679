import { IScrappedEpisode } from "../transformers/IScrappedEpisode";
import { durationToSeconds } from "../durationToSeconds";

let regulars = []

mikhalayaPetersonPodcastPlugin.regulars = regulars;

export function mikhalayaPetersonPodcastPlugin(ep: IScrappedEpisode): IScrappedEpisode {
  let matches = ep.title.match(/^([0-9]{1,3})\.?/)
  let { episode, title } = ep

  if (matches) {
    episode = +matches[1];
    title = title.replace(matches[0], "").trim();
  }

  return { ...ep, episode, title, duration: durationToSeconds(ep.duration) }
}
