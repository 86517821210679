<template>
  <div class="mt-5">
    <div>
      <crud-table endpoint="/api/imports" :form="ImportForm" upload>
        <el-table-column prop="file_name" label="Name" width="250"/>
        <el-table-column prop="datetime" label="Date" width="180">
          <template #default="scope">
            <div>{{ formatDate(scope.row.datetime) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="show_id" label="Show" width="180">
          <template #default="scope">
            <div>{{ idToShow(scope.row.show_id) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Import Actions">
          <template #default="scope">
            <div class="flex">
              <el-button class="me-4" type="info" size="small" @click="analyze(scope.row.id)">Analyze</el-button>
              Import:
              <el-button-group class="ms-1">
                <ActionButton type="primary" size="small" :getAction="`/api/imports/${scope.row.id}/create-episodes`">Episodes</ActionButton>
                <ActionButton type="primary" size="small" :getAction="`/api/imports/${scope.row.id}/create-personalities`">Guests</ActionButton>
                <ActionButton type="primary" size="small" :getAction="`/api/imports/${scope.row.id}/create-appearances`">Appearances</ActionButton>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </crud-table>

      <el-dialog v-model="dialogAnalyzeVisible" title="Analyze results" width="80%">
        <el-tabs v-model="activeName" class="demo-tabs">
          <el-tab-pane label="New episodes" name="new">
            <el-table :data="analyzedData.uniqueEpisodes">
              <el-table-column property="episode" label="Episode" width="150">
                <template #default="scope">
                  <div v-if="scope.row.db_id">
                    <span class="line-through">{{ scope.row.episode }}</span> <small>found by {{ scope.row.db_found_by}}</small>
                  </div>
                  <div v-else>
                    {{ scope.row.episode }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column property="date" label="Date" width="150" />
              <el-table-column property="title" label="Title" width="200" />
              <el-table-column property="personalities" label="New Personalities">
                <template #default="scope">
                  {{ analyzedData.newPersonalitiesMap[scope.row.guid] }}
                  <el-text class="line-through" type="info">{{ analyzedData.existingPersonalitiesMap[scope.row.guid] }}</el-text>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Discarded episodes" name="discarded">
            <el-table :data="analyzedData.discardedEpisodes">
              <el-table-column property="episode" label="Episode" width="150" />
              <el-table-column property="date" label="Date" width="150" />
              <el-table-column property="title" label="Title" width="200" />
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="New Personalities" name="personalities">
            <div class="row">
              <div class="col-6">
                <h4>Mapped</h4>
                <div v-for="(personalities, guid) in analyzedData.newPersonalitiesMap" :key="episode">
                  <span class="font-monospace">{{ guid }}</span>: {{ personalities }}
                </div>
              </div>
              <div class="col-6">
                <h4>All new personalities</h4>
                <div v-for="personality in analyzedData.newPersonalities" :key="personality">
                  {{personality}}
                </div>
              </div>
            </div>

          </el-tab-pane>
          <el-tab-pane label="Appearances" name="appearances">
            <el-alert title="Appearances are available after successful import of the episodes" type="info" />
            <div v-for="(personalities, episode) in analyzedData.appearancesMap" :key="episode">
              {{episode}}: {{personalities}}
            </div>
          </el-tab-pane>
        </el-tabs>

      </el-dialog>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import ImportForm from '@/components/forms/ImportForm.vue';
import { useRequest } from "@/mixins/useRequest";
import { formatDate} from "@/services/datetime";
import { ElMessage } from "element-plus";
import { eat } from "@/services/eat";
import CrudTable from "@/components/crud/CrudTable.vue";
import ActionButton from "@/components/ActionButton.vue";
import dayjs from "dayjs";

const shows = ref([])
const getShows = useRequest("GET", "/api/shows/all")

onMounted(() => {
  getShows().then(res => {
    shows.value = res;
  })
})

let activeName = ref("new")
let analyzedData = ref({})
let dialogAnalyzeVisible = ref(false)
let analyzeImport = useRequest("GET", (id: string) => `/api/imports/${id}/analyze`)

function analyze(id: string) {
  analyzedData.value = {};
  dialogAnalyzeVisible.value = true;
  analyzeImport(id).then(res => {
    analyzedData.value = res;
  })
}

function idToShow(show_id: string) {
  return shows.value.find(s => s.id === show_id)?.name
}


</script>
