import { reactive } from "vue";
import { isEqual } from "lodash";
import { io } from "socket.io-client";
import type { ITask } from "backend/actions/ITask";


export const state = reactive<{ connected: boolean, tasks: ITask[] }>({
  connected: false,
  tasks: []
});

// "undefined" means the URL will be computed from the `window.location` object
const url = process.env.NODE_ENV === "production" ? "/" : "http://localhost:3000";

export const socket = io(url, {
  //transports: ['websocket']
});

socket.on("connect", () => {
  state.connected = true;
});

socket.on("disconnect", () => {
  state.connected = false;
});

socket.onAny((event) => {
  console.log("onAny", event);
});

socket.on("task:add", (args:ITask) => {
  console.log("task:add", args)
  state.tasks.push(args)
});

socket.on("task:update", (args:ITask) => {
  let foundIndex = state.tasks.findIndex((t) => t.id === args.id)
  state.tasks[foundIndex]!.updates = args.updates;
});

socket.on("task:end", (args: ITask) => {
  let foundIndex = state.tasks.findIndex((t) => t.id === args.id)
  state.tasks[foundIndex]!.finished = true;
});