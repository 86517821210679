import { IScrappedEpisode } from "../transformers/IScrappedEpisode";
import { sanitizeGuests } from "./guestsUtils/sanitizeGuest";

let regulars = ["Dr. Andrew Huberman"]

hubermanLabPlugin.regulars = regulars;

const DISREGARD_STARTS_WITH = ["AMA ", "LIVE EVENT Q&A"]
const DISREGARD_FULL = [
  "Ketamine",
  "Master Stress",
  "Mental Health Toolkit",
  "Goals Toolkit",
  "The Science of MDMA & Its Therapeutic Uses",
  "Adderall, Stimulants & Modafinil for ADHD",
  "Focus Toolkit",
  "Sleep Toolkit"
]

export function hubermanLabPlugin(ep: IScrappedEpisode): IScrappedEpisode {
  let parts = ep.title.split(":");
  let guests: string[] = []

  if (parts.length === 1) {
    // empty body not to trigger else branch
  }
  else if (parts.length === 2) {
    if (!DISREGARD_FULL.includes(parts[0]) && !DISREGARD_STARTS_WITH.find(el => ep.title.startsWith(el))) {
      guests = [parts[0]
        .replace("GUEST SERIES |", "")
        .replace("Guest Series |", "")
        .replace("U.S. Surgeon General", "")
        .trim()
      ];
    }

    if (guests[0]?.includes("&")) {
      guests = guests[0].split("&")
    }

    guests = guests.map(g => g.trim())
  }
  else {
    throw new Error("Can not parse guests: " + ep.title)
  }

  ep.guests = sanitizeGuests(guests)

  return ep
}
