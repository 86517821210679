<template>
  <div :class="shift ? 'selecting' : ''">
    <slot />
  </div>
</template>

<script setup>
import { provide, ref, reactive, onMounted, onBeforeUnmount, watch, computed, watchEffect, defineExpose } from "vue"

let props = defineProps(["selection"])
let emit = defineEmits(["update:selection"])
let selection = reactive([])
let itemsOrder = reactive([])
let shift = ref(false)
let selectingFrom = ref("")
let lastSelection = ref("")

provide("selection", selection)
provide("selectionItemsOrder", itemsOrder)
provide("selectionLast", lastSelection)

defineExpose({
  clearSelection() {
    selection.length = 0;
  }
})

onMounted(() => {
  window.addEventListener("keydown", handleShiftDown);
  window.addEventListener("keyup", handleShiftUp);
})

onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleShiftDown);
  window.removeEventListener("keyup", handleShiftUp);
})

function handleShiftDown(evt) {
  if (evt.shiftKey) {
    shift.value = true
  }
}
function handleShiftUp(evt) {
  if (evt.key === "Shift") {
    shift.value = false
    lastSelection.value = ""
    selectingFrom.value = ""
  }
}

watch(selection, (value, oldValue) => {
  emit("update:selection", value)

  if (!shift.value) return;

  if (selectingFrom.value) {
    let selectingTo = lastSelection.value
    let fromIndex = itemsOrder.indexOf(selectingFrom.value)
    let toIndex = itemsOrder.indexOf(selectingTo)

    // if selecting from bottom, swap values
    if (fromIndex > toIndex) {
      let c = fromIndex;
      fromIndex = toIndex
      toIndex = c
    }

    for (let i = fromIndex; i < toIndex; i++) {
      if (!selection.includes(itemsOrder[i])) {
        selection.push(itemsOrder[i])
      }
    }
  }

  selectingFrom.value = lastSelection.value;
})
</script>

<style scoped>
.selecting {
  background: #ececff;
}
</style>
