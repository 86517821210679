<template>
  <el-form label-width="120px">
    <el-form-item label="Episode name">
      <el-input v-model="form.name"/>
    </el-form-item>
    <el-form-item label="Show name">
      <el-select v-model="form.show_id" filterable placeholder="Show">
        <el-option
            v-for="show in shows"
            :key="show.id"
            :label="show.name"
            :value="show.id"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Description">
      <el-input v-model="form.description" type="textarea" rows="4" />
    </el-form-item>
    <el-form-item label="Episode number">
      <el-input v-model="form.episode_number"/>
    </el-form-item>
    <el-form-item label="Air time">
      <el-date-picker
          v-model="form.datetime"
          type="datetime"
          placeholder="Select date and time"
      />
    </el-form-item>
    <JsonField v-model="form.links" label="Links" />
    <el-form-item label="Personalities">
      <div class="d-block">
        <div v-for="personality in form.personalities" :key="personality.id">
          {{ personality.full_name }} <el-tag v-if="personality.appeared_as === 'host'">Host</el-tag> <el-button size="small" type="danger" @click="removePersonality(personality.id)">x</el-button>
        </div>

        <div class="d-flex align-items-center">
          <el-select v-model="selectedGuest" filterable placeholder="Guest">
            <el-option
                v-for="person in allPersonalities"
                :key="person.id"
                :label="person.full_name"
                :value="person.id"
            />
          </el-select>

          <el-checkbox class="ms-1" v-model="isHost" label="Host?" size="large"/>
          <el-button class="ms-1" @click="addPersonality">Add Personality</el-button>
        </div>
      </div>
    </el-form-item>
    <el-form-item label="Features">
      <div>
        <div>
          <EpisodeFormFeature v-for="feature in form.features" :key="feature.id" :availableFeatures="allFeatures" :feature="feature" @update="handleUpdateFeature($event)" @remove="handleRemoveFeature($event)"/>
        </div>
        <div class="d-block">
          <el-button @click="addFeature">Add Feature</el-button>
        </div>
      </div>
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import { Options, Vue, mixins } from "vue-class-component";
import { cloneDeep, orderBy } from "lodash"
import Field from "@/components/field/Field.vue";
import CrudFormMixin from "@/components/crud/CrudFormMixin";
import RequestMixin from "@/mixins/RequestMixin";
import EpisodeFormFeature from "@/components/forms/EpisodeForm/EpisodeFormFeature.vue";
import JsonField from "@/components/field/JsonField.vue";

@Options({
  components: { JsonField, EpisodeFormFeature, Field },
  props: { modelValue: Object },
  mixins: [CrudFormMixin]
})
export default class EpisodeForm extends mixins(RequestMixin) {
  modelValue!: any;
  form:any = {
    show_id: null,
    personalities: [],
    features: [],
    name: "",
    description: "",
    episode_number: "",
    datetime: null
  };

  shows = [];
  allPersonalities: any[] = [];
  allFeatures: any[] = [];
  selectedShow = null;
  selectedGuest = null;
  isHost = false;

  created() {
    if (this.modelValue && this.modelValue.id) {
      this.form = cloneDeep(this.modelValue)
    }

    this.request("GET", "/api/shows/all").then(res => {
      this.shows = res;
    })
    this.request("GET", "/api/personalities/all").then(res => {
      this.allPersonalities = res;
    })
    this.request("GET", "/api/features/all").then(res => {
      this.allFeatures = res;
    })
  }

  addFeature() {
    if (!this.form.features) this.form.features = [];

    this.form.features.push({
      id: Math.floor((Math.random() * 10000000)) // just for vue purposes (and deleting new feature before episode was saved)
    })
  }

  addPersonality() {
    if (!this.form.personalities) this.form.personalities = [];

    this.form.personalities.push({
      id: this.selectedGuest,
      full_name: this.allPersonalities.find((foundP) => foundP.id === this.selectedGuest).full_name,
      appeared_as: this.isHost ? "host" : "guest"
    })
  }

  handleRemoveFeature(id) {
    this.form.features = this.form.features.filter(f => f.id !== id)
  }

  handleUpdateFeature(feature) {
    console.log(feature);
    let foundIndex = this.form.features.findIndex(f => f.id === feature.id);
    this.form.features[foundIndex] = feature;
  }

  removePersonality(id) {
    this.form.personalities = this.form.personalities.filter(p => p.id !== id)
  }
}
</script>
