<template>
  <div class="crud-table">
    <el-button v-if="!editOnly" class="mb-2" @click="handleCreate">Create new</el-button>

    <div class="mb-2" v-if="list.bulkActions && list.bulkActions.length > 0">
      <el-text size="small">Bulk Actions ({{ selection.length }}): </el-text>
      <el-button-group>
        <el-tooltip v-for="action in list.bulkActions" :key="action.name" :content="action.description" placement="bottom">
          <el-button @click="handleExecuteBulkAction(action.name)" type="primary" size="small">{{ action.title }}</el-button>
        </el-tooltip>
      </el-button-group>
    </div>
    <el-table :data="list.items" style="width: 100%" size="small" @sort-change="handleSortChange" @selection-change="handleSelectionChange" ref="table">
      <el-table-column type="selection" width="24"></el-table-column>
      <component :is="columnVnode" v-for="columnVnode in columns" :key="columnVnode.props.prop">
        <template #default="scope" v-if="columnVnode.children">
          <component :is="columnVnode.children.default" :row="scope.row" />
        </template>
        <template #header="{ column }" v-if="columnVnode.props.filterable">
          <div class="flex items-center" @click="($event) => handleSortClick($event, columnVnode.props.prop)">
            <div v-if="columnVnode.props?.ownSortable" class="pt-1 caret-wrapper">
              <i class="sort-caret ascending"></i>
              <i class="sort-caret descending"></i>
            </div>
            {{ column.label }}
          </div>
          <CrudHeaderFilter :column="column" :type="columnVnode.props.filterable" v-model="filter[column.property]" />
        </template>
      </component>
      <el-table-column label="Actions" :width="upload ? 200 : 160">
        <template #default="scope">
          <el-button-group>
            <el-button v-if="upload" size="small" @click="handleOpenUpload(scope.row)">Upload</el-button>
            <el-button size="small" @click="handleEdit(scope.row.id)">Edit</el-button>
            <el-button v-if="!editOnly" size="small" type="danger" @click="handleDelete(scope.row)">Delete</el-button>
          </el-button-group>
          <div class="mt-1" v-if="inlineBulk && list.bulkActions && list.bulkActions.length > 0">
            <el-dropdown>
              <el-button size="small" type="default">
                Actions
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-for="action in list.bulkActions" @click="handleExecuteSingleAction(scope.row.id, action.name)">{{ action.title }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex mt-2 mb-4">
      <el-pagination small background layout="prev, pager, next"
                     :page-size="pageSize"
                     :total="list?.total"
                     :current-page="page"
                     @update:current-page="handlePageUpdate"
                     class="me-4"
      />
      <el-select v-model="pageSize" class="me-2" size="small">
        <el-option v-for="item in availablePageSizes" :key="item" :value="item" />
      </el-select>
      <el-text size="small">Total: {{ list?.total }}</el-text>
    </div>

    <el-dialog v-model="editModalVisible" title="Edit" width="80%">
      <el-progress
        v-if="editLoading"
        :percentage="100"
        status="warning"
        :indeterminate="true"
        :duration="1"
      />
      <Component :is="form" v-if="editModalVisible && !editLoading" v-model="formValues"/>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeEditModal()">Cancel</el-button>
        <el-button type="primary" @click="handleSave">
          Confirm
        </el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog v-model="uploadModalVisible" title="Upload" width="80%">
      <el-upload
        class="upload-demo"
        drag
        :action="uploadActionUrl"
        multiple
        :headers="uploadHeaders"
      >
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">
          Drop file here or <em>click to upload</em>
        </div>
        <template #tip>
          <div class="el-upload__tip">
            jpg/png files with a size less than 500kb
          </div>
        </template>
      </el-upload>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCloseUploadModal">Finish</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { last } from "lodash"
import { mixins, Options } from "vue-class-component";
import { ElMessage, ElMessageBox } from 'element-plus'
import { debounce } from "lodash"
import { fromPairs, map, toPairs, pipe } from "lodash/fp"
import { UploadFilled } from '@element-plus/icons-vue'
import RequestMixin from "@/mixins/RequestMixin";
import { eat } from "@/services/eat";
import { baseUrl, headers } from "@/services/request";
import CrudHeaderFilter from "@/components/crud/CrudHeaderFilter.vue";
import { socket } from "../../services/socket"
import type { ITask } from "backend/actions/ITask";

@Options({
  components: { CrudHeaderFilter, UploadFilled },
  props: {
    endpoint: String,
    form: Object,
    inlineBulk: {
      type: Boolean,
      default: false
    },
    upload: {
      type: Boolean,
      default: false
    },
    editOnly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    pageSize(newVal, oldVal) {
      if (newVal === oldVal) return;
      this.loadData()
    },
    filter: {
      handler(newVal, oldVal) {
        let query = {}
        Object.entries(newVal).map(([name, value]) => {
          if (value === "") return;
          query["filter." + name] = value
        })

        this.$router.push({ query })
        this.debouncedLoad();
      },
      deep: true,
    }
  }
})
export default class CrudTable extends mixins(RequestMixin) {
  endpoint!: string
  filter: any = {};
  list: {items: any[], columns: any[], total: number, bulkActions: any[] } = {
    items: [],
    columns: [],
    total: 0,
    bulkActions: []
  };
  beColumns:any[] = []
  columns:any[] = [];
  editModalVisible = false;
  uploadModalVisible = false;
  uploadActionUrl = "";
  uploadHeaders = headers;
  formValues: any = {};
  form!: any;
  selection: any[] = [];
  sort = {
    column: "",
    prop: "",
    order: ""
  };
  page = 1;
  pageSize = 10;
  availablePageSizes = [10, 20, 50, 100, 500, 1000]
  debouncedLoad!: any;
  editLoading = false;

  loadData() {
    const operatorByCol = (colName) => {
      if (!this.list.columns) return;

      let foundCol = this.list.columns.find(c => c.column == colName)
      switch (foundCol?.type || "") {
        case "string":
          return "ilike"

        case "personality":
        case "show":
        case "number":
          return "equals"
        default:
          return "equals"
      }
    }

    let pagination = `page=${this.page}&limit=${this.pageSize}`

    let filterParts = Object.entries(this.filter).filter(([key, val]) => !!val).map(([key, val]) => `filter.${key}=${val}&filter.${key}.operator=${operatorByCol(key)}`)
    let filter = filterParts.join("&")
    let sort = `orderBy=${this.sort.prop}&order=${this.sort.order === "ascending" ? "asc" : "desc"}&${filter}`

    return this.request("get", `${this.endpoint}?${pagination}&${sort}`).then(res => {
      this.list = res;
      return res;
    })
  }

  created() {
    this.debouncedLoad = debounce(() => this.loadData(), 750)

    Object.entries(this.$router.currentRoute.value.query).forEach(([name, value]) => {
      if (value === "") return;

      let convValue: any = value;
      convValue = convValue == +convValue ? +convValue : convValue // convert to number if possible
      
      this.filter[name.replace("filter.", "")] = convValue;
    })

    socket.on("task:update", (args: ITask) => {
      let lastFinishedId = last(args.updates)?.finished ? last(args.updates)?.id : -1

      if (lastFinishedId === -1) return;

      let foundIndex = this.list.items.findIndex(i => i.id === lastFinishedId);

      this.debouncedLoad()
    });

    this.loadData().then((res) => {
      const col = (name) => res.columns.find((c) => c.column === name)
      this.beColumns = res.columns;
      this.columns = (this.$slots.default ? this.$slots.default() : []).map((s) => {
        let sortable = "sortable" in s.props! ? s.props.sortable : "custom"
        let filterable = s.props?.filterable || col((s as any).props.prop)?.type
        let jsonCol = s.props?.json || col((s as any).props.prop)?.type === "json"
        let ownSortable = false

        // prevent default sort header rendering in case we use custom header (to render filter)
        // since all of the header would still act as onclick sort change, which means any filter manipulation
        // would lead to sort change. So we are rendering custom sort component instead
        if (sortable && filterable) {
          sortable = false
          ownSortable = true
        }

        return {
          ...s,
          props: {
            ...s.props,
            sortable,
            ownSortable,
            filterable
          }
        }
      });

      let id = (this.$router.currentRoute).value.query?.id
      
      if (id || id === "0") {
        this.filter.id = id;
        console.log("should open edit")
        this.handleEdit(+id);
      }
    })
  }

  handleSortClick(evt, col) {
    let sortOrder = ""
    let tableRef = (this.$refs as any).table;

    const clearSort = () => {
      tableRef.clearSort();

      // clearSort won't call handler
      this.handleSortChange({
        sort: "",
        prop: "",
        column: ""
      })
    }

    if (this.sort.prop && (col !== this.sort.prop)) {
      clearSort()
      return
    }

    if (!this.sort.order) {
      sortOrder = "ascending"
    }
    else if (this.sort.order == "ascending") {
      sortOrder = "descending"
    }
    else {
      clearSort();
      return;
    }


    tableRef.sort(col, sortOrder)
  }

  handleSortChange(evt) {
    this.sort = evt;
    this.loadData()
  }

  handleSelectionChange(evt) {
    this.selection = evt.map(e => e.id);
    console.log(evt);
  }

  handleExecuteBulkAction(name: string) {
    this.request("post", `${this.endpoint}/bulk/${name}`, {
      ids: this.selection
    }).then(() => this.loadData())
  }

  handleExecuteSingleAction(id, name: string) {
    this.request("post", `${this.endpoint}/bulk/${name}`, {
      ids: [id]
    }).then(() => this.loadData())
  }

  handlePageUpdate(p) {
    this.page = p
    this.loadData()
  }

  handleOpenUpload(row: any) {
    this.uploadActionUrl = baseUrl + `${this.endpoint}/${row.id}/upload`;
    this.uploadModalVisible = true;
  }

  handleCloseUploadModal() {
    this.uploadModalVisible = false;
    this.loadData();
  }

  handleSave() {
    let url = this.endpoint;
    let method = "POST"

    if (this.formValues.id) {
      url = `${this.endpoint}/${this.formValues.id}`
      method = "PATCH"
    }

    // stringify json columns
    let transformedForm = pipe(
      toPairs,
      map(([key, value]) => {
        if (this.beColumns.find((c) => c.column === key)?.type === "json") {
          return [key, JSON.stringify(value || [])]
        }
        return [key, value]
      }),
      fromPairs
    )(this.formValues)

    eat(this.request(method, url, transformedForm)
        .then(() => this.loadData())
        .finally(() => this.closeEditModal()))
  }

  handleCreate() {
    this.formValues = {};
    this.editModalVisible = true;
  }

  handleEdit(id) {
    this.$router.push({ query: { id } })
    this.editLoading = true;
    this.request("get", `${this.endpoint}/${id}`).then(res => {
      this.formValues = res;
      this.editModalVisible = true;
      this.editLoading = false;
    })
  }

  closeEditModal() {
    this.$router.push({ query: {} })
    this.editModalVisible = false
  }

  handleDelete(row) {
    eat(ElMessageBox.confirm(
        'This will permanently delete the record',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
    )
        .then(() => {
          eat(this.request("delete", `${this.endpoint}/${row.id}`).then(() => {
            ElMessage({
              type: 'success',
              message: 'Delete completed',
            })
            return this.loadData();
          }))
        }))

  }
}
</script>
<style scoped>
.crud-table >>> .el-table .el-table__header .el-table__cell {
  vertical-align: top;
}
</style>
