import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-block" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "d-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_JsonField = _resolveComponent("JsonField")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_EpisodeFormFeature = _resolveComponent("EpisodeFormFeature")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, { "label-width": "120px" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "Episode name" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.form.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Show name" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.form.show_id,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.show_id) = $event)),
            filterable: "",
            placeholder: "Show"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shows, (show) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: show.id,
                  label: show.name,
                  value: show.id
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Description" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.form.description,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.description) = $event)),
            type: "textarea",
            rows: "4"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Episode number" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.form.episode_number,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.episode_number) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Air time" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.form.datetime,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.datetime) = $event)),
            type: "datetime",
            placeholder: "Select date and time"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_JsonField, {
        modelValue: _ctx.form.links,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.links) = $event)),
        label: "Links"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_el_form_item, { label: "Personalities" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.personalities, (personality) => {
              return (_openBlock(), _createElementBlock("div", {
                key: personality.id
              }, [
                _createTextVNode(_toDisplayString(personality.full_name) + " ", 1),
                (personality.appeared_as === 'host')
                  ? (_openBlock(), _createBlock(_component_el_tag, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode("Host")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createTextVNode(),
                _createVNode(_component_el_button, {
                  size: "small",
                  type: "danger",
                  onClick: ($event: any) => (_ctx.removePersonality(personality.id))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("x")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_select, {
                modelValue: _ctx.selectedGuest,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedGuest) = $event)),
                filterable: "",
                placeholder: "Guest"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allPersonalities, (person) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: person.id,
                      label: person.full_name,
                      value: person.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_checkbox, {
                class: "ms-1",
                modelValue: _ctx.isHost,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isHost) = $event)),
                label: "Host?",
                size: "large"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_el_button, {
                class: "ms-1",
                onClick: _ctx.addPersonality
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Add Personality")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "Features" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.features, (feature) => {
                return (_openBlock(), _createBlock(_component_EpisodeFormFeature, {
                  key: feature.id,
                  availableFeatures: _ctx.allFeatures,
                  feature: feature,
                  onUpdate: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleUpdateFeature($event))),
                  onRemove: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleRemoveFeature($event)))
                }, null, 8, ["availableFeatures", "feature"]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_button, { onClick: _ctx.addFeature }, {
                default: _withCtx(() => [
                  _createTextVNode("Add Feature")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}