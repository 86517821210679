<template>
  <el-form label-width="200px">
    <Field label="Name" v-model="form.name"/>
    <Field label="Source" v-model="form.source"/>
    <Field label="Youtube Channel Id" v-model="form.youtube_channel_id"/>
    <JsonField label="Links" v-model="form.links"/>
    <el-form-item label="Transformer">
      <v-select class="w-100" v-model="form.transformer" :options="availableTransformers" label="Transformer"/>
    </el-form-item>
    <el-form-item label="Transformer plugin">
      <v-select class="w-100" v-model="form.transformer_plugin" :options="availableTransformerPlugins" label="Transformer plugin"/>
    </el-form-item>
    <el-form-item label="Guests Parser">
      <v-select class="w-100" v-model="form.guest_parser" :options="availableGuestParsers" label="name" :reduce="(row) => row.name"/>
      <div class="w-100">
        <GuestParserOptions v-if="selectedParserParams" :params="selectedParserParams" v-model="parserParamsValues"/>
      </div>
    </el-form-item>
    <el-form-item label="Guests Parser Params">
      <el-input disabled v-model="parserParamsValuesJson" />
    </el-form-item>
    <el-form-item label="Image">
      <el-input disabled v-model="form.image_uuid" />
      <img class="previewImg me-2" v-if="form.image_uuid" :src="baseImageUrl + '/' + form.image_uuid">
      <br />
      <el-upload
        class="upload-demo"
        ref="uploadRef"
        v-model:file-list="fileList"
        :on-success="handleSuccess"
        :on-change="handleChange"
        :headers="uploadHeaders"
        drag
        :action="uploadUrl"
        method="POST"
        :auto-upload="false"
      >
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">
          Drop file here or <em>click to upload</em>
        </div>
        <template #tip>
          <div class="el-upload__tip">
            jpg/png files with a size less than 500kb
          </div>
        </template>
      </el-upload>
    </el-form-item>

    <div v-if="form.id">
      <el-form-item label="Regularly hosted by">
        <div class="d-block">
          <div v-for="personality in regularHosts" :key="personality.personality_id">
            {{ personality.full_name }}
            <el-button size="small" type="danger" @click="removeRegularHost(personality.personality_id)">x</el-button>
          </div>

          <div class="d-flex align-items-center">
            <el-select v-model="selectedHost" filterable placeholder="Host">
              <el-option
                v-for="person in availablePersonalities"
                :key="person.id"
                :label="person.full_name"
                :value="person.id"
              />
            </el-select>

            <el-date-picker
              v-model="fromDate"
              type="date"
              placeholder="From"
              size="small"
            />
            <el-date-picker
              v-model="toDate"
              type="date"
              placeholder="To"
              size="small"
            />

            <el-button class="ms-1" @click="addRegularHost">Add Regular Host</el-button>
          </div>
        </div>
      </el-form-item>
    </div>
  </el-form>
</template>

<script lang="ts">
import dayjs from "dayjs"
import { mixins, Options, Vue } from "vue-class-component";
import { cloneDeep } from "lodash"
import Field from "@/components/field/Field.vue";
import CrudFormMixin from "@/components/crud/CrudFormMixin";
import RequestMixin from "@/mixins/RequestMixin";
import vSelect from "vue-select";
import GuestParserOptions from "@/components/guestParser/GuestParserOptions.vue";
import JsonField from "@/components/field/JsonField.vue";
import { UploadFilled } from "@element-plus/icons-vue";
import { baseUrl, headers } from "@/services/request";

@Options({
  components: { UploadFilled, JsonField, GuestParserOptions, Field, vSelect },
  props: { modelValue: Object },
  mixins: [CrudFormMixin],
  computed: {
    parserParamsValuesJson() {
      try {
        // not ideal... but you know...
        this.form.guest_parser_params = JSON.stringify(this.parserParamsValues || {})
        return JSON.stringify(this.parserParamsValues || {})
      }
      catch (e) {
        return ""
      }
    },
    selectedParserParams() {
      let foundParser = this.availableGuestParsers.find(el => el.name === this.form.guest_parser);
      if (foundParser) {
        return foundParser.params
      }

      return null;
    }
  }
})
export default class ShowForm extends mixins(RequestMixin) {
  baseImageUrl = process.env.VUE_APP_CF_WORKER_URL || "";
  availablePersonalities: any[] = []
  selectedHost: any = "";
  regularHosts: any[] = []
  fromDate: string = "";
  toDate: string = "";
  modelValue!: any;
  availableTransformers = []
  availableTransformerPlugins = []
  availableGuestParsers = []
  uploadUrl = baseUrl + "/api/r2-upload"
  uploadHeaders = headers;
  fileList = []
  form = {
    guest_parser: "",
    guest_parser_params: "",
    transformer: "",
    name: "",
    id: "",
    source: "",
    links: "",
    youtube_channel_id: "",
    image_uuid: ""
  };
  parserParamsValues:any = {};

  $refs: {
    uploadRef?: any
  } = {}

  created() {
    if (this.modelValue && this.modelValue.id) {
      this.form = cloneDeep(this.modelValue)
    }

    if (this.form.id) {
      this.loadRegularHosts()
      this.parserParamsValues = JSON.parse(this.form.guest_parser_params || "{}")
    }

    this.request("GET", "/api/personalities/all").then(res => {
      this.availablePersonalities = res;
    })

    this.request("GET", "/api/transformers").then(res => {
      this.availableTransformers = res;
    })

    this.request("GET", "/api/transformer-plugins").then(res => {
      this.availableTransformerPlugins = res;
    })

    this.request("GET", "/api/guest-parsers").then(res => {
      this.availableGuestParsers = res;
    })
  }

  handleSuccess(e) {
    this.form.image_uuid = e.uuid;
  }

  handleChange(e) {
    if (e.status !== "ready") return

    this.$refs.uploadRef.submit();
  }

  loadRegularHosts() {
    this.request("GET", `/api/shows/${this.form.id}/regular-hosts`).then(res => {
      this.regularHosts = res;
    })
  }

  removeRegularHost(id: string) {
    this.request("DELETE", `/api/shows/${this.form.id}/regular-host/${id}`).then(res => this.loadRegularHosts())
  }

  addRegularHost() {
    this.request("PUT", `/api/shows/${this.form.id}/regular-host`, {
      id: this.selectedHost,
      hosted_from: this.fromDate ? dayjs(this.fromDate).format("YYYY-MM-DD") : "",
      hosted_to: this.toDate ? dayjs(this.toDate).format("YYYY-MM-DD") : ""
    }).then(res => this.loadRegularHosts())
  }
}
</script>

<style scoped>
.previewImg {
  height: 150px;
}
</style>
