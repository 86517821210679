<template>
  <div class="highlight-names" @click="handlePersonClick($event)" @mouseover="hovered = true" @mouseout="hovered = false">
    <div v-if="hovered" v-html="highlightedContent"></div>
    <div v-if="!hovered" v-html="content"></div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue"
import { escapeRegExp } from "lodash"
import compromise from "compromise"

let hovered = ref(false);
let { content } = defineProps(["content"]);
let emit = defineEmits(["onNameClick"]);

function handlePersonClick(event) {
  if (event.target.nodeName !== "SPAN") return;

  // not using modifiers, since we're only conditionally stopping the event
  event.stopPropagation()
  emit("onNameClick", event.target.innerText);
}

let highlightedContent = computed(() => {
  let nlpDoc = compromise(content)
  let people = nlpDoc.people().json().map(person => person.text);

  let localContent = content

  people.forEach(person => {
    const regex = new RegExp(`\\b${escapeRegExp(person)}\\b`, 'g');
    localContent = localContent.replace(regex, `<span class="highlight">${person}</span>`);
  });

  return localContent;
})

</script>

<style scoped>
.highlight-names >>> .highlight {
  color: white;
  cursor: pointer;
  background: #d7600b;
}
</style>
