import { Options, Vue } from "vue-class-component";
import { request } from "@/services/request";
import { ElMessage } from "element-plus";

@Options({})
export default class RequestMixin extends Vue {
  request(method: string, url: string, data?: any) {
    return request(method, url, data).catch((e) => {
      ElMessage.error(`Request failed: ${e.response.status} ${e.response.statusText}`)

      return Promise.reject(e)
    })
  }
}