<template>
  <el-form-item :label="label">
    <el-input v-model="localValue"  :readonly="props.readonly" />
  </el-form-item>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';

const props = defineProps({
  label: String,
  modelValue: String,
  readonly: {
    required: false,
    default: false
  }
});
const emit = defineEmits(["update:modelValue"]);

const localValue = ref(props.modelValue);

watch(localValue, (newValue) => {
  emit('update:modelValue', newValue);
});

watch(() => props.modelValue, (newValue) => {
  if (newValue !== localValue.value) {
    localValue.value = newValue;
  }
});
</script>
