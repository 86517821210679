<template>
  <div class="text-xs mb-2 pb-1">
    <strong>{{ item.originalTitle }}</strong><br>
    {{ item.title }}<br>
    <div class="flex">
      <div class="me-2 text-blue-700">{{ item.episode }}</div>
      <div class="me-2 text-green-800" v-for="guest in pluginResult?.guests">{{ guest }}</div>
    </div>
    <hr>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { runEditablePlugin } from "@/services/runEditablePlugin"
const pluginResult = ref(null)

let props = defineProps(["item", "code"])
let result = { }

watch(() => props.code, (newVal, oldVal) => {
  pluginResult.value = runEditablePlugin(props.item, newVal?.value)
})
</script>
