import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_crud_table = _resolveComponent("crud-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_crud_table, {
        endpoint: "/api/shows",
        form: _ctx.ShowForm
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "id",
            label: "ID",
            width: "60",
            sortable: "custom"
          }),
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "Name",
            width: "180",
            sortable: "custom"
          }),
          _createVNode(_component_el_table_column, {
            prop: "links",
            label: "Links"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(Array.isArray(scope.row.links) ? scope.row.links.join(", ") : ""), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "deleted_guids",
            label: "Deleted GUIDs"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(Array.isArray(scope.row.deleted_guids) ? scope.row.deleted_guids.length : 0), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["form"])
    ])
  ]))
}