<template>
  <el-form label-width="200px">
    <Field label="Full name" v-model="form.full_name"/>
    <JsonField label="Alternative names" v-model="form.alternative_names" />
    <JsonField label="Aliases" v-model="form.aliases" />

    <hr>
    <div v-if="form.id !== null">
      <div class="d-flex">
        <div class="me-1 align-self-center">Merge&nbsp;into:</div>
        <v-select class="w-100 me-1" v-model="selectedPersonality" :options="personalities" label="full_name" :reduce="p => p.id" />
        <el-button type="warning" @click="mergeWith">Merge</el-button>
      </div>
    </div>


    <div v-if="appearances.length > 0 && form.id !== null">
      <hr>
      <div>Appearances</div>
      <div class="d-inline me-2" v-for="appearance in appearances" :key="appearance.id">
        <small>episodeId {{ appearance.show_episode_id }} as {{ appearance.appeared_as }}</small>
      </div>
    </div>
  </el-form>
</template>

<script lang="ts">
import { mixins, Options, Vue } from "vue-class-component";
import { cloneDeep } from "lodash"
import vSelect from "vue-select";

import Field from "@/components/field/Field.vue";
import CrudFormMixin from "@/components/crud/CrudFormMixin";
import RequestMixin from "@/mixins/RequestMixin";
import { eat } from "@/services/eat";
import { ElMessage, ElMessageBox } from "element-plus";
import JsonField from "@/components/field/JsonField.vue";


@Options({
  components: { JsonField, Field, vSelect },
  props: { modelValue: Object },
  mixins: [CrudFormMixin],

})
export default class PersonalityForm extends mixins(RequestMixin) {
  modelValue!: any;
  form = {
    full_name: "",
    alternative_names: [],
    id: null
  };
  selectedPersonality = null;
  appearances = [];
  personalities = [];

  created() {
    if (this.modelValue && this.modelValue.id) {
      this.form = cloneDeep(this.modelValue)
    }

    if (this.form.id === null) {
      return
    }

    this.request("GET", `/api/personalities/${this.form.id}/appearances`).then(res => {
      this.appearances = res;
    })

    this.request("GET", `/api/personalities/all`).then(res => {
      this.personalities = res.filter(p => p.id !== this.form.id);
    })
  }

  mergeWith() {
    eat(ElMessageBox.confirm(
      'This will delete the currently selected personality, add it\'s name to the one from the dropdown/select alternative names and update all appearances of the selected personality to the new one',
      'Warning',
      {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
    )
      .then(() => {
        eat(this.request("delete", `/api/personalities/${this.form.id}/merge/${this.selectedPersonality}`).then(() => {
          ElMessage({
            type: 'success',
            message: 'Merged completed',
          })
        }))
      }))
  }
}
</script>
