<template>
  <div class="cell-container">
    <slot />
  </div>
</template>

<script setup>
</script>

<style>
.cell-container {
  width: 300px;
  transition: min-width 0.3s ease-in-out;
  position: relative; /* Important for z-index to work */;
  white-space: nowrap;
  overflow: hidden;
}

.cell-container:hover {
  width: auto; /* Adjust this as needed to fit content */
  min-width: 600px;
  white-space: normal;
  max-width: 100%; /* Prevent it from growing beyond the container */
  z-index: 2; /* Bring it on top of adjacent elements */
  overflow: visible;
  background: #e8e8e8;
  border: 1px solid #ffc9c9;
  margin: -1px;
}
</style>
