<template>
  <router-link :to="{ name: 'episode-detail', params: { id: episode.id } }" class="episode block hover:no-underline rounded-lg hover:shadow cursor-pointer mb-4 border py-2 px-3 border-slate-400 overflow-hidden text-sm">
    <div class="flex gap-2">
      <h3><span v-if="episode.episode_number">{{ episode.episode_number }}. </span>{{ episode.name }}</h3>
    </div>
    <div class="flex">
      <div class="font-bold text-slate-700">
        <a class="hover:underline" @click.capture.prevent="$emit('click:show', episode.id)">{{ episode.show_name }}</a>
      </div>
      <div class="flex ms-auto">
        <div class="personality" v-for="p in orderedPersonalities" :key="p.id">
          <a class="hover:underline" @click.capture.prevent="$emit('click:personality', p.id)" v-if="p.appeared_as === 'host'"><strong>{{ p.full_name }}</strong></a>
          <a class="hover:underline" @click.capture.prevent="$emit('click:personality', p.id)" v-else>{{ p.full_name }}</a>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { orderBy } from "lodash"

let emit = defineEmits(["click:personality", "click:show"])
let { episode } = defineProps(['episode'])

let orderedPersonalities = computed(() => {
  return orderBy(episode.personalities, "appeared_as", "desc")
})
</script>
<style scoped>
.personality {
  @apply inline-block bg-gray-200 rounded-full px-3 py-1 text-sm text-gray-700 me-2 mb-2
}
</style>
