import { IScrappedEpisode } from "../transformers/IScrappedEpisode";
import compromise from "compromise"
import { sanitizeGuests } from "./guestsUtils/sanitizeGuest";

// timcastIrlPlugin.regulars = ["Tim Pool", "Adam Crigler", "Lydia Leitermann"];
timcastIrlPlugin.hint = `Best way is to start from the bottom, select all regular episodes (skip the bonus ones) and run the plugin.
Then go episode by episode, and use description and NLP to add the extra guest(s). Then for the bonus episodes, find the one episode from the previous week that was with the guest, and use selection to copy the guests from that episode.`
timcastIrlPlugin.regulars = ["Tim Pool", "Hannah Claire Brimelow", "Ian Crossland", "Lydia Leitermann"];
timcastIrlPlugin.customPrompt = ""

let nickMap = {
  Tim: "Tim Pool",
  Ian: "Ian Crossland",
  Lydia: "Lydia Leitermann",
  Seamus: "Seamus Coughlin",
  Luke: "Luke Rudkowski",
  Brett: "Brett Dasovic",
  Mary: "Mary Morgan",
  Jamie: "Jamie Kilstein",
  Libby: "Libby Emmons",
  Hannah: "Hannah Claire Brimelow",
  Serge: "Serge du Preez",
  Kellen: "Kellen",
  Phil: "Phil Labonte",
  Elad: "Elad Eliahu",
  Taylor: "Taylormay Silverman",
  Carter: "Carter Banks",
  Shane: "Shane Cashman",
  Clint: "Clint Russel",
  Raymond: "Raymond Stanley",

  ["Hannah Claire"]: "Hannah Claire Brimelow",
  ["Phil Labonte"]: "Phil Labonte",
  ["Shane Cashman"]: "Shane Cashman",
  ["Clint Russel"]: "Clint Russel",
}

export function timcastIrlPlugin(ep: IScrappedEpisode): IScrappedEpisode {
  let guests = ep.guests || []
  let episode = ep.episode;

  ep.title = ep.title.replace(/Timcast IRL #[0-9]{1-3}/, "")

  let foundIndex = ep.description.search(/ join(ed)?|host /)
  let nlpGuests: string[] = []

  if (foundIndex) {
    let beforeJoinedDescription = ep.description.substring(0, foundIndex);
    let afterJoinedDescription = ep.description.substring(foundIndex);

    Object.entries(nickMap).forEach(([key, value]) => {
      if (beforeJoinedDescription.includes(key)) {
        guests.push(value)
      }
    })

    let nlpDoc = compromise(afterJoinedDescription)
    let people = nlpDoc.people().json().filter(person => {
      return person.person.firstName && person.person.lastName
    });

    // nlpGuests = people.map(person => person.text);
  }

  guests = guests.concat(nlpGuests)
  guests = sanitizeGuests(guests)
  ep.guests = guests;

  return { ...ep, guests, episode }
}
