<template>
  <div class="mt-5">
    <div>
      <crud-table endpoint="/api/shows" :form="ShowForm">
        <el-table-column prop="id" label="ID" width="60" sortable="custom" />
        <el-table-column prop="name" label="Name" width="180" sortable="custom" />
        <el-table-column prop="links" label="Links">
          <template #default="scope">
            {{ Array.isArray(scope.row.links) ? scope.row.links.join(", ") : "" }}
          </template>
        </el-table-column>
        <el-table-column prop="deleted_guids" label="Deleted GUIDs">
          <template #default="scope">
            {{ Array.isArray(scope.row.deleted_guids) ? scope.row.deleted_guids.length : 0 }}
          </template>
        </el-table-column>
      </crud-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CrudTable from "@/components/crud/CrudTable.vue";
import ShowForm from "@/components/forms/ShowForm.vue";
import GuestParserOptions from "@/components/guestParser/GuestParserOptions.vue";

@Options({
  components: { GuestParserOptions, ShowForm, CrudTable }
})
export default class AdminShowsView extends Vue {
  shows = [];
  ShowForm = ShowForm;

  created() {
  }
}
</script>
