import axios from "axios"

let isProd = process.env.NODE_ENV === "production";
export let baseUrl = isProd ? "" : "http://localhost:3000"
export let headers = { "x-session-id": localStorage.getItem("podcastr-session-id")}

export function request(method: string, url: string, body?: any) {
  return axios({
    data: body,
    method,
    url: baseUrl + url,
    headers,
  }).then(({ data }) => data)
}
