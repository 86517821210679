<template>
  <div class="mt-5">
    <div class="row">
      <div class="col">
        <h2>dist0</h2>
        <DuplicatePersonality v-for="p in mappedPersonaliteis[0]" :key="p.personality.id" :personality="p.personality"
          @merge="handleMerge" :matches="p.matches" />
      </div>
      <div class="col">
        <h2>dist1</h2>
        <DuplicatePersonality v-for="p in mappedPersonaliteis[1]" :key="p.personality.id" :personality="p.personality"
          @merge="handleMerge" :matches="p.matches" />
      </div>
      <div class="col">
        <h2>dist2</h2>
        <DuplicatePersonality v-for="p in mappedPersonaliteis[2]" :key="p.personality.id" :personality="p.personality"
          @merge="handleMerge" :matches="p.matches" />
      </div>
      <div class="col">
        <h2>dist3-5</h2>
        <DuplicatePersonality v-for="p in mappedPersonaliteis['3to5']" :key="p.personality.id"
          @merge="handleMerge" :personality="p.personality" :matches="p.matches" />
      </div>
    </div>
  </div>
</template>

<script setup>

import { reactive } from "vue";
import { useRequest } from "@/mixins/useRequest";
import { distance } from "fastest-levenshtein"
import DuplicatePersonality from "@/components/DuplicatePersonality"

function handleMerge() {
  console.log("should reload")
}

let getPersonalites = useRequest("GET", "/api/personalities/all")
let mappedPersonaliteis = reactive({
  0: [],
  1: [],
  2: [],
  '3to5': [],
  '6to10': []
});

getPersonalites().then(res => {
  // find names which have 0 moves
  res.forEach((p => {
    let found0 = []
    let found1 = []
    let found2 = []
    let found3to5 = []
    let found6to10 = []

    res.forEach(compP => {
      if (p.id === compP.id) return;

      let dst = distance(p.full_name, compP.full_name)
      if (dst === 0 || (p.full_name === compP.full_name)) {
        found0.push(compP)
      }
      if (dst === 1) {
        found1.push(compP)
      }
      if (dst === 2) {
        found2.push(compP)
      }
      if (dst >= 3 && dst <= 5) {
        found3to5.push(compP)
      }
      if (dst >= 6 && dst <= 10) {
        found6to10.push(compP)
      }
    })

    if (found0.length > 0) {
      mappedPersonaliteis[0].push({
        personality: p,
        matches: found0
      })
    }

    if (found1.length > 0) {
      mappedPersonaliteis[1].push({
        personality: p,
        matches: found1
      })
    }

    if (found2.length > 0) {
      mappedPersonaliteis[2].push({
        personality: p,
        matches: found2
      })
    }

    if (found3to5.length > 0) {
      mappedPersonaliteis["3to5"].push({
        personality: p,
        matches: found3to5
      })
    }

    if (found6to10.length > 0) {
      mappedPersonaliteis["6to10"].push({
        personality: p,
        matches: found6to10
      })
    }
  }))
})

console.log(mappedPersonaliteis)

</script>
