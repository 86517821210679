<template>
  <div class="mt-5">
    <div>
      <crud-table ref="table" endpoint="/api/scrapes" :form="Form">
        <el-table-column prop="id" label="ID" width="40" />
        <el-table-column label="Show" width="150">
          <template #default="scope">
            {{ idToShow(scope.row.show_id) }}
          </template>
        </el-table-column>
        <el-table-column label="Engines" width="300">
          <template #default="scope">
            <el-text size="small">
              {{ scope.row.source }}<br>
              {{ scope.row.guest_parser }}<br>
              {{ scope.row.transformer }}<br>
              {{ scope.row.transformer_plugin }}
            </el-text>
          </template>
        </el-table-column>
        <el-table-column label="Results" width="150">
          <template #default="scope">
            <div>
              <el-tag v-if="scope.row.result">JSON</el-tag>
            </div>
            <div class="mt-1">
              <el-tag v-if="scope.row.csv_result">CSV</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Scraping">
          <template #default="scope">
            <div class="flex">
              <el-button-group>
                <el-popconfirm title="Confirm?" @confirm="scrape(scope.row.id)">
                  <template #reference>
                    <el-button type="info" size="small">Scrape</el-button>
                  </template>
                </el-popconfirm>
                <el-button v-if="scope.row.result" type="info" size="small"
                           @click="openJsonModal(scope.row)">Show JSON
                </el-button>
                <el-button v-if="scope.row.result" type="info" size="small" @click="saveCsv(scope.row.id)">
                  Create CSV
                </el-button>
                <el-button v-if="scope.row.result && scope.row.csv_result" type="info" size="small"
                           @click="download(scope.row)">Download CSV
                </el-button>
                <el-button type="info" size="small"
                           @click="openImportModal(scope.row)">Create Import
                </el-button>
                <el-button type="info" size="small"
                           @click="saveRemoved(scope.row)">Save Removed GUIDs
                </el-button>
              </el-button-group>
            </div>
            <div class="mt-1"><el-button @click="openPluginEditor(scope.row)" size="small">Edit plugin</el-button></div>
          </template>
        </el-table-column>
      </crud-table>
    </div>

    <el-dialog v-model="importModalVisible" title="Create new import" width="80%">
      <el-input v-model="importName" />
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="importModalSave">Save</el-button>
        <el-button @click="importModalVisible = false">Close</el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog v-model="jsonModalVisible" title="JSON View" width="80%">
      <ScrapeJsonView :result="selectedResults" :plugin="selectedPlugin" :scrape-id="selectedScrape.id" />
      <JsonViewer v-if="jsonModalVisible" :value="selectedResults" />

      <template #footer>
      <span class="dialog-footer">
        <el-button @click="jsonModalSave">Save</el-button>
        <el-button @click="jsonModalVisible = false">Close</el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="pluginEditorVisible" title="Plugin Editor" width="90%">
      <PluginEditor :result="selectedResults" :scrape-id="selectedScrapeId" :show-id="selectedScrape.show_id" />

      <template #footer>
      <span class="dialog-footer">
        <el-button @click="jsonModalSave">Save</el-button>
        <el-button @click="pluginEditorVisible = false">Close</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { mixins, Options, Vue } from 'vue-class-component';
import { last, first } from "lodash"

import CrudTable from '@/components/crud/CrudTable.vue';
import ScrapeForm from '@/components/forms/ScrapeForm.vue';
import RequestMixin from '@/mixins/RequestMixin';
import { eat } from '@/services/eat';
import { ElMessage } from 'element-plus';
import ScrapeJsonView from '@/components/scrapeJsonView/ScrapeJsonView.vue';
import PluginEditor from "@/components/pluginEditor/PluginEditor.vue";


@Options({
  components: {
    PluginEditor,
    ScrapeJsonView,
    CrudTable
  }
})
export default class AdminEpisodesView extends mixins(RequestMixin) {
  Form = ScrapeForm;
  availableShows:any[] = [];
  importModalVisible = false;
  jsonModalVisible = false;
  pluginEditorVisible = false;
  selectedScrape: any = {};
  selectedResults: any = {
    deleted: []
  };
  selectedPlugin = "";
  selectedParser = "";
	selectedScrapeId = "";
  importName = "";

  created() {
    eat(this.request("get", "/api/shows/all").then(res => {
      this.availableShows = res;
    }))
  }

  idToShow(show_id: string) {
    return this.availableShows.find(s => s.id === show_id)?.name
  }

  reloadTable() {
    (this.$refs as any).table.loadData();
  }

  scrape(id) {
    eat(this.request('GET', `/api/scrapes/${id}/exec`).then(res => {
      ElMessage({
        type: 'success',
        message: 'Scraped',
      })
      this.reloadTable();
    }))
  }

  extend(id) {
    eat(this.request('GET', `/api/scrapes/${id}/extend`).then(res => {
      ElMessage({
        type: 'success',
        message: 'Scraped',
      })
      this.reloadTable();
    }))
  }

  openPluginEditor(scrape) {
    let { result, transformer_plugin } = scrape;
    this.pluginEditorVisible = true;
    this.selectedScrape = scrape;
    this.selectedResults = JSON.parse(result);
    this.selectedPlugin = transformer_plugin;
  }

  openImportModal({ id, show_id, result }) {
    this.selectedScrapeId = id;
    this.importModalVisible = true;
    let { items } = JSON.parse(result)
    this.importName = this.idToShow(show_id) + ": " + last<any>(items.filter(ep => !!ep.episode)).episode + " - " + first<any>(items.filter(ep => !!ep.episode)).episode
  }

  importModalSave() {
    eat(this.request("POST", `/api/imports/scrape/` + this.selectedScrapeId, {
        file_name: this.importName
    }).then(() => {
      this.importModalVisible = false;
      ElMessage({
        type: "success",
        message: `Created new import named [${this.importName}]`,
      })
    }))
  }

  openJsonModal(scrape) {
    let { result, transformer_plugin, guest_parser } = scrape;
    this.jsonModalVisible = true;
    this.selectedScrape = scrape;
    this.selectedResults = JSON.parse(result);
    this.selectedPlugin = transformer_plugin;
    this.selectedParser = guest_parser;

    if (!this.selectedResults.deleted) {
      this.selectedResults.deleted = []
    }
  }

  jsonModalSave() {
    eat(this.request("PATCH", `/api/scrapes/${this.selectedScrape.id}`, {
      result: JSON.stringify(this.selectedResults)
    }).then(res => {
      ElMessage({
        type: "success",
        message: "Updated result changes",
      })
      this.reloadTable();
    }))
    this.jsonModalVisible = false
  }

  saveCsv(id) {
    eat(this.request("GET", `/api/scrapes/${id}/csv`).then(res => {
      ElMessage({
        type: "success",
        message: "Converted to CSV",
      })
      this.reloadTable();
    }))
  }

  saveRemoved(row) {
    eat(this.request("GET", `/api/scrapes/${row.id}/save-removed`));
  }

  download(row) {
    let { items } = JSON.parse(row.result)
    let name = this.idToShow(row.show_id) + "__"  + first<any>(items).episode + "-" + last<any>(items).episode
    const href = URL.createObjectURL(new Blob([row.csv_result], { type: 'text/plain' }));

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${name}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
}
</script>
