<template>
  <div class="px-5 pt-2">
    <h2 class="text-lg pb-2">This is a very secret page, one could even say top secret, ney, ultra secret</h2>

    <div class="flex items-center">
      <label for="custom-episode-url">YouTube URL</label>
      <input class="border-1 border ml-2 p-2 border-black" type="text" v-model="youtubeUrl" id="custom-episode-url">
      <button class="border-1 border p-2 border-black hover:underline" @click="loadEpisodeDetails">load details</button>
    </div>

    <div v-if="episodeDetails">
      found episode:
      <YoutubeDetailValue label="title" :value="episodeDetails.snippet.title"/>
      <YoutubeDetailValue label="channel" :value="episodeDetails.snippet.channelTitle"/>
      <YoutubeDetailValue label="views" :value="episodeDetails.statistics.viewCount"/>

      <button class="border-1 border p-2 border-black hover:underline" @click="createEpisode">create episode</button>
    </div>

    <div class="mt-4">
      <div class="mb-2">existing custom episodes:</div>
      <div v-for="item in list">
        <div class="flex text-sm items-center gap-2">
          <div><router-link :to="{ name: 'episode-detail', params: { id: item.id } }" class="block hover:underline rounded-lg hover:shadow cursor-pointer text-blue-600">{{ item.name }}</router-link></div>
          <router-link class="border-1 border p-2 border-black hover:underline" :to="{ name: 'admin.episodes', query: { id: item.id }}">show in admin</router-link>
          <button class="border-1 border p-2 border-black hover:underline">download transcript</button>
          <button class="border-1 border p-2 border-black hover:underline">run ai</button>
          <button class="border-1 border p-2 border-black hover:underline bg-red-300" @click="handleDeleteEpisode(item.id)">delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRequest } from "@/mixins/useRequest";
import YoutubeDetailValue from "@/components/frontend/YoutubeDetailValue.vue";


let youtubeUrl = ref("hNlzzVl6uWs")
let episodeDetails = ref(null)
let variousShow = ref(null)
let list = ref([])
let getDetails = useRequest("GET", (id) => `/api/public/yt-details/${id}`)
let postEpisode = useRequest("POST", `/api/episodes`)
let patchEpisode = useRequest("PATCH", (id) => `/api/episodes/${id}`)
let deleteEpisode = useRequest("DELETE", (id) => `/api/episodes/${id}`)
let getVariousShow = useRequest("GET", `/api/various-episodes-show`)

let getList = useRequest("GET", (showId) => `/api/episodes?filter.show_id=${showId}&filter.show_id.operator=equals`)

getVariousShow().then(res => {
  variousShow.value = res[0];
  loadList();
})

function getYouTubeVideoId(url) {
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
}


function loadList() {
  getList(variousShow.value.id).then(listRes => {
    list.value = listRes.items
  })
}

function loadEpisodeDetails() {
  episodeDetails.value = null;
  youtubeUrl.value = getYouTubeVideoId(youtubeUrl.value)
  getDetails(youtubeUrl.value).then(res => {
    episodeDetails.value = res;
  })
}

function createEpisode() {
  postEpisode({
    name: `${episodeDetails.value.snippet.channelTitle} - ${episodeDetails.value.snippet.title}`,
    show_id: variousShow.value.id,
    episode_number: 0
  }).then(res => patchEpisode(res[0].id, {
    ...res[0],
    features: [
      { type: "feature:youtube:id", value: youtubeUrl.value },
      { type: "feature:youtube:title", value: episodeDetails.value.snippet.title }
    ]
  })).then(() => loadList())
}

function handleDeleteEpisode(id) {
  deleteEpisode(id).then(() => loadList())
}

</script>
