import { IScrappedEpisode } from "../transformers/IScrappedEpisode";
import { sanitizeGuests } from "./guestsUtils/sanitizeGuest";

let regulars = ["Shane Gillis", "Mark Normand", "Ari Shaffir"]

joeRoganPlugin.regulars = regulars;

export function joeRoganPlugin(ep: IScrappedEpisode): IScrappedEpisode {
  let regex = /^\#([0-9]{1,4}) ?[–-]{1} ?(.*)$/i
  let splitRegex = /,|vs |& | and /
  let title = ep.title
  let matches = title.match(regex);


  let episode = ep.episode || 0;
  let guests: string[] = ep.guests || [];
  let hasGuests = guests?.length > 0

  if (matches && !hasGuests) {
    episode = +matches[1];
    guests = matches[2].split(splitRegex);  // Splitting guests into groups if there are multiple guests
    // title = matches[3];
  }

  if (guests.length === 2) {
    let foundEp = ep.title.match(/\| ?EP ?([0-9]{1,3})/)
    if (foundEp) {
      episode = +foundEp[1];
    }
  }

  ep.episode = episode;
  ep.guests = sanitizeGuests(guests);

  return ep;
}
