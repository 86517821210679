<template>
  <div>
    <div v-for="show in shows">{{ show.name }}</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { request } from "@/services/request";

@Options({
  components: {
  },
  data() {
    return {
      shows: []
    }
  },
  created() {
    request("GET", "/api/shows").then(res => {
      this.shows = res;
    })
  }
})
export default class ShowsView extends Vue {
}
</script>
