import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import EpisodesView from "@/views/EpisodesView.vue";
import PersonalitiesView from "@/views/PersonalitiesView.vue";
import ShowsView from "@/views/ShowsView.vue";
import MyEpisodesView from "@/views/MyEpisodesView.vue";
import AdminView from "@/views/AdminView.vue";
import AdminEpisodesView from "@/views/AdminEpisodesView.vue";
import AdminShowsView from "@/views/AdminShowsView.vue";
import AdminPersonalitiesView from "@/views/AdminPersonalitiesView.vue";
import AdminImportView from '@/views/AdminImportView.vue';
import AdminScrapesView from '@/views/AdminScrapesView.vue';
import AdminDuplicatePersonalitiesView from '@/views/AdminDuplicatePersonalitiesView.vue';
import AdminBabysitterView from "@/views/AdminBabysitterView.vue";
import EpisodeDetailView from "@/views/EpisodeDetailView.vue";
import AdminTimelineView from "@/views/AdminTimelineView.vue";
import CustomEpisodesView from "@/views/CustomEpisodesView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "episodes",
    component: EpisodesView,
  },
  {
    path: "/episode/:id",
    name: "episode-detail",
    component: EpisodeDetailView
  },
  {
    path: "/personalities",
    name: "personalities",
    component: PersonalitiesView
  },
  {
    path: "/shows",
    name: "shows",
    component: ShowsView
  },
  {
    path: "/custom-episodes",
    name: "custom-episodes",
    component: CustomEpisodesView
  },
  {
    path: "/my-episodes",
    name: "my-episodes",
    component: MyEpisodesView
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
    children: [
      {
        path: "timeline",
        name: "admin.timeline",
        component: AdminTimelineView
      },
      {
        path: "shows",
        name: "admin.shows",
        component: AdminShowsView
      },
      {
        path: "personalities",
        name: "admin.personalities",
        component: AdminPersonalitiesView
      },
      {
        path: "duplicate-personalities",
        name: "admin.duplicate-personalities",
        component: AdminDuplicatePersonalitiesView
      },
      {
        path: "episodes",
        name: "admin.episodes",
        component: AdminEpisodesView
      },
      {
        path: "import",
        name: "admin.import",
        component: AdminImportView
      },
      {
        path: "scrapes",
        name: "admin.scrapes",
        component: AdminScrapesView
      },
      {
        path: "babysitter",
        name: "admin.babysitter",
        component: AdminBabysitterView
      }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
